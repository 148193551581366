import React from 'react';
import { Slider } from 'antd';
import { MinusCirle, PlusCirle } from '../../../../../../components/imgExport';

interface IconSliderProps {
    max: number;
    min: number;
    pointTeaser?: number
    setPointTeaser?: (value: number) => void
}

const IconSlider: React.FC<IconSliderProps> = (props) => {
    const { max, min, pointTeaser, setPointTeaser } = props;


    const handleDecrease = () => {
        if (Number(pointTeaser) > min) {
            setPointTeaser?.(Number(pointTeaser) - 0.5);
        }
    };

    const handleIncrease = () => {
        if (Number(pointTeaser) < max) {
            setPointTeaser?.(Number(pointTeaser) + 0.5);
        }
    };

    return (
        <>
            <div className="icon-wrapper flex gap-[15px]">
                <img src={MinusCirle}
                    alt="MinusCirle"
                    onClick={handleDecrease} />
                <Slider
                    className='w-full' {...props}
                    onChange={setPointTeaser}
                    value={Number(pointTeaser)}
                    max={7}
                    step={0.5}
                />
                <img src={PlusCirle}
                    alt="PlusCirle"
                    onClick={handleIncrease} />
            </div>
            <div className='flex justify-center gap-1 items-center'>
                {(() => {
                    const options = [];

                    for (let i = 4; i <= 7; i += 0.5) {
                        options.push(
                            <span className={`${Number(pointTeaser) === i ? "text-[#475eec]" : "text-[#898989] text-[13px]"}`}>{i}</span>
                        );
                        if (i < 7)
                            options.push(
                                <span className='font-bold-GT-America text-[14px] flex items-center pb-2 text-[#898989]'>. . .</span>
                            );
                    }

                    return options;
                })()}
            </div>
        </>

    );
};


export default IconSlider;
