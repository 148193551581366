import { Drawer } from "antd"
import './style.css'
import { itemMenu } from "../HeadNavi_V2";
// import { useState } from "react";
interface DrawerProp {
    openDrawer: boolean
    menu: itemMenu[]
    tournaments: itemMenu

    setTournaments: (value: itemMenu) => void
    closeDrawer: (value: boolean) => void

}
export const DrawerLeftMenu: React.FC<DrawerProp> = ({
    openDrawer, menu, tournaments,
    closeDrawer, setTournaments
}) => {
    return (
        <Drawer
            open={openDrawer}
            onClose={() => closeDrawer(false)}
            closeIcon={false}
            width={147}
            placement="right"
            className="!bg-[#131620] p-none-drawer xs:!block xl:!hidden"
            maskClassName="xs:!block xl:!hidden"
        >
            <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col bg-[#131620] w-full">
                    {menu.map((items) => (
                        <div
                            key={items.id}
                            className='border-b border-b-[#202531] h-[96px] flex  items-center pl-7'
                            onClick={() => setTournaments(items)}
                        >
                            <div className='flex items-center gap-2'>
                                {items.name === tournaments.name ?
                                    <img src={items.iconActive} alt="" />
                                    :
                                    <img src={items.icon} alt="" />
                                }
                                <span className={`text-sm uppercase font-medium  ${items.name === tournaments.name ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}>{items.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </Drawer>

    )
}