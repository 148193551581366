/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import '../page/homepage/HomePage.css'
import '../page/DetailPage/styles.css'
interface DashBoardProps {
}
const PageNotFound: React.FC<DashBoardProps> = () => {
  return (
    <div className='w-full h-[100vh] flex flex-col items-center'>
      You don't have an internet connection
      <img className='w-full' src={`/pagenotfound.jpg`} alt="" />
    </div>

  )
}
export default PageNotFound;