import { empty } from "../imgExport"

interface EmtyBetSlipProps {
    activeKey?: string
}
const EmtyBetSlip: React.FC<EmtyBetSlipProps> = ({ activeKey }) => {
    return (
        <div className="flex flex-col gap-3 items-center py-6">
            <img src={empty} alt="" />
            <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>

            {activeKey && activeKey === "2" ?
                <span className='text-xs fw-[400] text-[#C1C9E1] px-14'>A Parlay is a bet on two or more teams or selections. You can combine different sports,points, spreads and money lines. To win the parlay all selections mustwin.
                </span>
                :
                (activeKey && activeKey === "3" ?
                    <span className='text-xs fw-[400] text-[#C1C9E1] px-14'>Teasers allow you to combine 2+ Football or Basketball selections from the Spread and Total Match Point bet types and allow you to alter the spread, to increase the probability of winning.
                    </span>
                    :
                    <span className='text-xs fw-[400] text-[#C1C9E1] px-14'>Pick your winner! 1 Event. 1 Bet. Easy!
                        Win or lose your stake.
                    </span>
                )
            }

        </div>
    )
}
export default EmtyBetSlip