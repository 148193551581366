import React, { useState } from 'react';
import { Dropdown, Tabs } from 'antd';
import type { MenuProps, TabsProps } from 'antd';
import { logoKB_2, search, menuser_circle_whiteu, Logo_KB_3, edit, lock_open, sign_out } from '../../../../../components/imgExport';
import '../../stylesMainNavi.css'
import { DrawerLeftMenu } from './Drawer/DrawerLeftMenu';
import { ModalChangePassWord } from '../../../../../components/HeaderTabNew/Modal/ModalChangePassWord';
import { ModalChangeUserName } from '../../../../../components/HeaderTabNew/Modal/ModalChangeUserName';
import { itemMenu } from './HeadNavi_V2';
import tokenService from '../../../../../services/token'

interface Props {

    openDrawer?: boolean
    menu: itemMenu[]
    tournaments: itemMenu

    closeDrawer?: (value: boolean) => void
    setTournaments: (value: itemMenu) => void
}

const onChange = (key: string) => {
    console.log(key);
};

// const items: TabsProps['items'] = [
//     {
//         key: '1',
//         label: 'Sports',
//     },
//     {
//         key: '2',
//         label: 'Live in Game',
//     },
// ];

const tabMargin = 30;
// const tabBarGutter = (items.length - 1) * tabMargin;

const HeadTabs_V2: React.FC<Props> = ({
    menu, tournaments,
    setTournaments
}) => {
    const [openDrawerMenu, setOpenDrawerMenu] = useState(false)
    const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false)
    const [openModalChangeUserName, setOpenModalChangeUserName] = useState<boolean>(false)
    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: <span className="text-sm text-[#C1C9E1] uppercase">Setting</span>,
            children: [
                {
                    key: '1-1',
                    label: <span
                        className="text-xs text-[#C1C9E1]  flex items-center gap-3 uppercase"
                        onClick={() => setOpenModalChangeUserName(true)}
                    >
                        <img src={edit} alt="" className='w-5 h-5' />
                        Change username
                    </span>,
                },
                {
                    key: '1-2',
                    label: <span
                        className="text-xs text-[#C1C9E1]  flex items-center gap-3 uppercase"
                        onClick={() => setOpenModalChangePass(true)}
                    >
                        <img src={lock_open} alt="" className='w-5 h-5' />
                        Change password
                    </span>,
                },
                {
                    key: '1-3',
                    label: <span
                        className="text-xs text-[#D4B962]  flex items-center gap-3 uppercase"
                        onClick={() => {
                            tokenService.removeToken()
                            window.location.href = "/login";
                        }}
                    >
                        <img src={sign_out} alt="" />
                        log out
                    </span>,
                },
            ],
        },
    ];

    return (
        <div className="xs:relative xs:pl-6 xs:pr-2 xl:pr-0 xl:pl-0 xs:flex xs:justify-between xl:flex xl:items-center xl:justify-center  xl:border-b xl:border-b-[#202531] xs:border-0 bg-[#131620] cursor-pointer">
            <DrawerLeftMenu
                openDrawer={openDrawerMenu}
                menu={menu}
                tournaments={tournaments}
                setTournaments={setTournaments}
                closeDrawer={() => setOpenDrawerMenu(false)}
            />
            <ModalChangePassWord
                isOpen={openModalChangePass}
                closeModal={() => setOpenModalChangePass(false)}
            />
            <ModalChangeUserName
                isOpen={openModalChangeUserName}
                closeModal={() => setOpenModalChangeUserName(false)}
            />
            <img src={logoKB_2} alt="Logo" className='w-[48px] h-[48px] xs:block xl:hidden' onClick={() => { window.location.href = '/Soccer_V2' }} />
            <img src={Logo_KB_3} alt="Logo" className='w-[110px] h-[44px] xs:hidden xl:block' onClick={() => { window.location.href = '/Soccer_V2' }} />
            <div className="xs:flex items-center  xl:hidden gap-1">
                <Dropdown menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }} >
                    <img src={menuser_circle_whiteu} alt="" />
                </Dropdown>
                <button
                    className="uppercase rounded-lg bg-[#D4B962] py-1 px-[14px] text-sm text-[#131620] font-bold-GT-America "
                    onClick={() => setOpenDrawerMenu(true)}
                >
                    Sports
                </button>
            </div>
            {/* <div className='hidden w-[173px] mr-[28px] ml-[28px] '>

                <Tabs
                    className="headTab"
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                    tabBarGutter={tabBarGutter}
                />
            </div> */}
            <div className='hidden'>
                <img src={search} alt="Search" />
            </div>

        </div>
    )
}




export default HeadTabs_V2;
