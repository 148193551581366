import { Button } from "antd";
import { CloseSquareOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Close } from "../../../../components/imgExport";
import './style.css'

interface TotalStakeProps {
    keyBetTabs: string,
    showTotalStake: boolean,
    showTotalStake_V2: boolean,
    numberStake: number,
    totalAmountParlay?: number
    showHideTotalStake: () => void,
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleDeleteClick: (index: number) => void
    handleSuccess: () => void
    maxInputStake?: () => void

}
const CaculateComponent_V2 = ({ showTotalStake, showTotalStake_V2, numberStake, keyBetTabs, totalAmountParlay,
    handleClick, handleNumberClick, handleDeleteClick, handleSuccess, showHideTotalStake, maxInputStake }: TotalStakeProps) => {
    return (
        <div>
            <div className={` ${showTotalStake_V2 ? 'xs:flex xl:hidden flex-col gap-4' : 'hidden'}`}>
                <div className="flex flex-col">
                    <div className='grid grid-cols-5 gap-2'>
                        <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(50)} >+50
                        </Button>
                        <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(100)} >+100
                        </Button>
                        <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(200)} >+200
                        </Button>
                        <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(500)} >+500
                        </Button>
                        <Button className="!h-8  !bg-[#D4B962] !text-[#131620] !border-none button-font-bold-GT-America" onClick={() => maxInputStake && maxInputStake()}>Max</Button>

                    </div>
                    <div className="flex items-center gap-1 w-full">
                        <div className='grid grid-cols-5 gap-1 py-2 w-full'>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(1)}>1</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(2)}>2</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(3)}>3</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(4)}>4</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(5)}>5</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(6)}>6</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(7)}>7</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(8)}>8</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(9)}>9</Button>
                            <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(0)}>0</Button>
                        </div>
                        <Button className="!h-[60px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleDeleteClick(0)}><CloseSquareOutlined /></Button>

                    </div>

                </div>
            </div>
            <div className={` ${showTotalStake ? 'xs:hidden visibleStake  bg-[#131620]  pt-4 px-4 rounded-sm border border-[#202531]' : 'hiddenStake'}`}>
                <div className="cursor-pointer flex justify-end" onClick={() => showHideTotalStake()}>
                    <img src={Close} alt="" />
                </div>
                <div className="flex items-center justify-between">
                    <p className="text-sm text-[#FFFFFF] font-bold-GT-America">Stake</p>
                    <div className="w-[206px] h-[33px] rounded-xl border border-[#E4E7F1] flex items-center justify-between px-3">
                        {
                            keyBetTabs === '1' && <span className="text-sm text-[#E4E7F1]">
                                {numberStake === 0 ? '' : (<span className="">$</span>)}
                                {numberStake === 0 ? 'Stake per bet' : `${numberStake} `}
                            </span>
                        }
                        {
                            keyBetTabs === '2' && <span className="text-sm text-[#E4E7F1]">
                                {totalAmountParlay === 0 ? '' : (<span className="">$</span>)}
                                {totalAmountParlay === 0 ? 'Stake per bet' : `${totalAmountParlay} `}
                            </span>
                        }
                        <span className="text-sm text-[#666E97]">$</span>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className='grid grid-cols-4 gap-1'>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(5)} >+5
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(10)} >+10
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(15)} >+15
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(20)} >+20
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(30)} >+30
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(50)} >+50
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(100)} >+100
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                        <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(200)} >+200
                            <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                        </Button>
                    </div>
                    <div className="flex items-center gap-1 w-full">
                        <div className='grid grid-cols-4 gap-1 py-2 w-[85%]'>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(1)}>1</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(2)}>2</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(3)}>3</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(4)}>4</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(5)}>5</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(6)}>6</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(7)}>7</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(8)}>8</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(9)}>9</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(0)}>0</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(0)}>.</Button>
                            <Button className="!h-[52px]  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleDeleteClick(0)}><CloseSquareOutlined /></Button>

                            {/* <Button className="!h-[66px] rounded-md !bg-[#20273D] !text-white !bg-opacity-20 !color-[black] !button-font-bold-GT-America " type="primary" onClick={handleSuccess}>OK</Button> */}
                        </div>
                        <Button className="!h-[164px] !w-[15%] !bg-[#D4B962] !text-[#131620] !border-none button-font-bold-GT-America" onClick={handleSuccess}>OK</Button>
                    </div>

                </div>

            </div>
        </div>

    )
}
export default CaculateComponent_V2