/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import { WinningMargin } from '../../services/data';

interface ThreeColItems {
    name: string;
    detailTotal1: string;
    detailTotal2: string;
    detailTotal3: string;
}

interface OneColItems {
    Total1: string;
    detailTotal1: string;
}
const ThreeColItems = ({ detailTotal1, detailTotal2, detailTotal3, name }: ThreeColItems) => {
    return (
        <div className='flex items-center '>
            <div className='w-1/2'>
                <p className='text-left font-bold-GT-America'>{name}</p>
            </div>
            <div className='w-1/2 flex gap-1'>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold-GT-America text-[#262626]'>{detailTotal1}</p>
                </div>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold-GT-America text-[#262626]'>{detailTotal2}</p>
                </div>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold-GT-America text-[#262626]'>{detailTotal3}</p>
                </div>
            </div>
        </div>
    )
}

const OneColItems = ({ Total1, detailTotal1 }: OneColItems) => {
    return (
        <div className='flex items-center '>
            <div className='w-full flex gap-1'>
                <div className='w-1/2'>
                    <p className='text-left font-bold-GT-America text-[#262626]'>{Total1}</p>
                </div>
                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold-GT-America text-[#262626]'>{detailTotal1}</p>
                </div>
            </div>
        </div>
    )
}

const MLBFuture: React.FC = () => {

    const outringtWinner: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Outringt Winner',
            children:
                <div>
                    <div className='w-full flex justify-end'>
                        <div className='w-1/2  h-10 flex gap-1'>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>Winner</p>
                            </div>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>East</p>
                            </div>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>West</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <ThreeColItems detailTotal1='4.25' detailTotal2='2.30' detailTotal3='-' name='Dodgers' />
                        <ThreeColItems detailTotal1='5.25' detailTotal2='-' detailTotal3='2.90' name='Braves' />
                        <ThreeColItems detailTotal1='5.50' detailTotal2='3.00' detailTotal3='-' name='Yankees' />
                    </div>
                </div>,
        }
    ];
    const superBowl: CollapseProps['items'] = [
        {
            key: '1',
            label: 'World Series - League of Winning Team',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold-GT-America'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold-GT-America'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const centralDivision: CollapseProps['items'] = [
        {
            key: '1',
            label: 'World Series - State of Winning Team',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold-GT-America'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold-GT-America'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const winningMargin: CollapseProps['items'] = [
        {
            key: '1',
            label: 'World Series - Division of Winning Team',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold-GT-America'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold-GT-America'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    return (
        <div className='mt-[22px] detailContent flex flex-col gap-3'>
            <div className='w-full'>
                <Collapse items={outringtWinner} defaultActiveKey={['1']} />
            </div>
            <div className='w-full flex gap-3'>
                <div className='w-1/2'>
                    <Collapse items={superBowl} defaultActiveKey={['1']} />
                    <Collapse items={centralDivision} defaultActiveKey={['1']} />
                </div>
                <div className='w-1/2'>
                    <Collapse items={winningMargin} defaultActiveKey={['1']} />
                </div>
            </div>
        </div>
    )
}

export default MLBFuture;