import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useLocation, useNavigate } from 'react-router-dom';
import { Statement_Gold, Statement_Grey, top_icon } from '../imgExport';
import { useEffect, useState } from 'react';
interface footerProps {
    countBetslip?: number
    setOpenDrawerBestSlip: (value: boolean) => void
}
export const FooterMobile: React.FC<footerProps> = ({
    countBetslip,
    setOpenDrawerBestSlip
}) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])


    return (
        <div className='flex  fixed bottom-[-1px] w-full bg-[#131620] justify-between items-center h-[56px] px-8'>
            <div className={` flex items-center gap-2  cursor-pointer text-sm uppercase font-medium text-[#C1C9E1] hover:text-[#D4B962] rounded-tr-lg ${location.pathname === '/mybet' ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}
                onClick={() => navigate('/mybet')}
            >
                <BookmarkIcon className='text-[24px]' />
                my bet
            </div>

            <div className={` flex items-center gap-2  cursor-pointer text-sm uppercase font-medium text-[#C1C9E1] div-statement-hover rounded-tl-lg ${location.pathname === '/statement' ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}
                onClick={() => navigate('/statement')}
            >
                {location.pathname === '/statement' ?
                    <img src={Statement_Gold} alt="" className='h-[28px]' />
                    :
                    <>
                        <img src={Statement_Grey} alt="" className='h-[28px] block statement-hover-hidden' />
                        <img src={Statement_Gold} alt="" className='h-[28px] hidden statement-hover' />
                    </>
                }
                Statement
            </div>
            <button
                className="uppercase rounded-lg bg-[#D4B962] py-2 px-[14px] text-sm text-[#131620] font-bold-GT-America absolute bottom-[35px] xs:left-[37%] xs:right-[37%] md:left-[43%] md:right-[43%]  "
                onClick={() => setOpenDrawerBestSlip(true)}
            >
                bet slip {countBetslip ? `(${countBetslip})` : ""}
            </button>
            <button className={`w-8 h-8 rounded-circle bg-[#666E97] absolute right-[30px] top-[-20px] ${isVisible ? 'flex justify-center items-center' : 'hidden'}`}
                onClick={ScrollToTop}
            >
                <img src={top_icon} alt="" />
            </button>
        </div>
    )
}