import { Button } from "antd";
import { CloseSquareOutlined, CaretRightOutlined } from '@ant-design/icons';


interface TotalStakeProps {
    showTotalStake: boolean,
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleDeleteClick: (index: number) => void
    handleSuccess: () => void
    maxInputStake?: () => void

}
const CaculateV2 = ({ showTotalStake, handleClick, handleDeleteClick, handleNumberClick, handleSuccess, maxInputStake }: TotalStakeProps) => {
    return (
        <div className={`pt-2 ${showTotalStake ? '' : 'hidden'}`}>
            <div className="flex flex-col">
                <div className='grid grid-cols-4 gap-2'>
                    <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(5)} >+50
                    </Button>
                    <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(10)} >+100
                    </Button>
                    <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(20)} >+200
                    </Button>
                    <Button className="!h-8 !bg-[#20273D] !text-white !border-none button-font-bold-GT-America relative" onClick={() => handleClick(50)} >+500
                    </Button>
                    <Button className="!h-8  !bg-[#D4B962] !text-[#131620] !border-none button-font-bold-GT-America" onClick={() => maxInputStake && maxInputStake()}>Max</Button>
                </div>
                <div className="flex items-center gap-1 w-full">
                    <div className='grid grid-cols-6 gap-1 py-2 w-full'>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(1)}>1</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(2)}>2</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(3)}>3</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(4)}>4</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(5)}>5</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleDeleteClick(0)}><CloseSquareOutlined /></Button>

                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(6)}>6</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(7)}>7</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(8)}>8</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(9)}>9</Button>
                        <Button className="!h-7  !bg-[#20273D] !text-white !border-none button-font-bold-GT-America" onClick={() => handleNumberClick(0)}>0</Button>


                    </div>

                </div>

            </div>
        </div>
    )
}
export default CaculateV2