import { Delete_Left } from "../../../../components/imgExport";
interface CaculateOTPProps {
  clickNumber: (x: number | string) => void;
}
const CaculateOTP: React.FC<CaculateOTPProps> = ({
  clickNumber
}) => {
  return (
    <div>
      <div className='flex flex-wrap justify-center keyBoard pb-3'>
        <div className='flex flex-wrap justify-center gap-1 w-[300px] h-[164px]'>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(1)}>1</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(2)}>2</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(3)}>3</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(4)}>4</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(5)}>5</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(6)}>6</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(7)}>7</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(8)}>8</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(9)}>9</button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber(0)}>0</button>
          <button className=" !h-[52] !w-[72px] rounded-md border-otp-noactive !text-[#FFFFFF] !color-[black] !font-bold-GT-America flex justify-center items-center" onClick={() => clickNumber('.')}>
            <img src={Delete_Left} alt="" />
          </button>
          <button className="!h-[52] !w-[72px] border-otp-noactive !text-[#FFFFFF] rounded-[6px]" onClick={() => clickNumber('del')}>C</button>

        </div>
      </div>
    </div>
  )
}
export default CaculateOTP