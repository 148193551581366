/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { InfoTournamentSoccer } from '../../../../../api/homePage/homePageApi';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import { checkNoData } from '../../../../../until';
import SoccerTopV3 from './SoccerTopV3';
import { LogoWithoutText, video } from '../../../../../components/imgExport';
import useDebounce from '../../../../../hook/useDebounce';
import LazyLoad from 'react-lazyload';
import { API_URL_SOCCER } from '../../../../../env';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import io from 'socket.io-client';
import useThrottle from '../../../../../hook/useThrottle';
interface GameSoccerProps {
    keyBetTabsSoccer?: string
    loadTournament?: string
    setLoadTournament?: () => void
    tournament: string
    item: InfoTournamentSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    listParlayBetsSlipSoccer?: bettingApi.InfoParlaySoccerTmp[]
    dateFilterSoccer?: string
    resetBetGame?: boolean
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
    typeListGameSoccer?: "main" | "select"

    // id: string
    // tournament: string
    // itemGame: InfoGameCR
    // listChooseBet: ChooseBet[]

    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string
    ) => void
    countTotalSoccer?: (
        count: number
    ) => void
    updateBetGameReset?: () => void
    checkUpdateOdds?: (id: number, point: number, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => void
    blockGameID?: (id: number | string) => void
    removeEndOfGamePrematch?: (id: number) => void
    setTotalGameSoccer?: (total: number) => void
}

const GameSoccer_V2: React.FC<GameSoccerProps> = ({
    keyBetTabsSoccer, loadTournament, tournament, item, listChooseBet, listAddStrightBetsSlipSoccer, listParlayBetsSlipSoccer, dateFilterSoccer, resetBetGame, listDateFilterSoccer, typeListGameSoccer,
    chooseBetSoccer, countTotalSoccer, updateBetGameReset, setLoadTournament, checkUpdateOdds, checkUpdateStrightBetsSlip, removeEndOfGamePrematch, setTotalGameSoccer

}) => {
    const pushToast = useToast();

    const [listGameSoccer, setListGameSoccer] = useState<homePage_V2Api.InfoGameSoccer[]>([])
    const [socket, setSocket] = useState<any>(null);

    //Soccer
    const gGameSoccerByTournamentName = useDebounce(async () => {
        // pushLoading(true);
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (dateFilterSoccer === "all") {
            const ketQua = await homePage_V2Api.allGameByTournamentSoccerSbobet(item.tournament, "", timeZone);
            if (ketQua?.success) {
                // setListGameSoccer(ketQua.data)
                const updateList = ketQua.data.map((item: any) => ({
                    ...item,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
            } else {
                setListGameSoccer([]);
                pushToast(ketQua?.message, "warn");
            }
        } else {
            const ketQua = await homePage_V2Api.getGameSoccerByTournamentName(item.tournament, dateFilterSoccer, timeZone);
            if (ketQua?.success) {
                countTotalSoccer?.(ketQua.data.length)
                // setListGameSoccer(ketQua.data)
                const updateList = ketQua.data.map((item: any) => ({
                    ...item,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
            } else {
                setListGameSoccer([]);
                pushToast(ketQua?.message, "warn");
            }
        }
        // pushLoading(false);
    }, 500)

    const totalGamePrematchSoccer = useRef<number>()

    useEffect(() => {
        if (listDateFilterSoccer && listDateFilterSoccer[0] && listDateFilterSoccer[0].value && listDateFilterSoccer[0].value === dateFilterSoccer) {
            const socketInstance = io(API_URL_SOCCER);
            setSocket(socketInstance);
            socketInstance.on('connect', () => {
                console.log('Connected to server prematch');
            });
            socketInstance.on('message-prematch-soccer', async (data) => {
                var dataFilter = data.filter((item: any) => (new Date(item.realKickoffTime).getDate() === new Date().getUTCDate()))
                const updateList = dataFilter.filter((bet: any) => (bet.tournament.tournamentName === tournament)).map((item: any) => ({
                    ...item,
                    oddsOld: listGameSoccer.filter((betOld: any) => (betOld.id === item.id))[0]?.odds,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
                totalGamePrematchSoccer.current = data.length
                // setTotalGameSoccer?.(data.length)
            });
            return () => {
                if (socketInstance) {
                    socketInstance.disconnect();
                }
            };
        }
    }, [dateFilterSoccer]);
    const checkEndOfPrematch = useThrottle(() => {
        // if (keyBetTabsSoccer === '1') {
        if (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0 && item.tournament === listAddStrightBetsSlipSoccer[0].tournament) {
            const listGameIdSoccer = listGameSoccer.map(item => item.id);
            // listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.map((item, index) => {
            //     if (!listGameIdSoccer.includes(Number(item.idGame)) && item.tournament === tournament) {
            //         removeEndOfGamePrematch && removeEndOfGamePrematch(Number(item.idGame))
            //     }
            // })
            listGameSoccer.map((item, index) => {
                if (item.subtractTimeClient <= 60000 && listGameIdSoccer.includes(Number(listAddStrightBetsSlipSoccer[0].idGame)) && item.id === listAddStrightBetsSlipSoccer[0].idGame) {
                    removeEndOfGamePrematch && removeEndOfGamePrematch(Number(item.id))
                }
            })
        }
        // } else {
        if (listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.length > 0 && item.tournament === listParlayBetsSlipSoccer[0].tournament) {
            const listGameIdSoccer = listGameSoccer.map(item => item.id);
            // listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.map((item, index) => {
            //     if (!listGameIdSoccer.includes(Number(item.idGame)) && item.tournament === tournament) {
            //         removeEndOfGamePrematch && removeEndOfGamePrematch(Number(item.idGame))
            //     }
            // })
            listGameSoccer.map((item, index) => {
                listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.map((itemParlay, indexParlay) => {
                    if (item.subtractTimeClient <= 60000 && listGameIdSoccer.includes(Number(itemParlay.idGame)) && item.id === itemParlay.idGame) {
                        removeEndOfGamePrematch && removeEndOfGamePrematch(Number(itemParlay.idGame))
                    }
                })

            })
        }
        // }
    }, 500)

    useEffect(() => {
        if (listChooseBet.length > 0 && listDateFilterSoccer && listDateFilterSoccer[0].value === dateFilterSoccer) {
            checkEndOfPrematch()
        }
        if (typeListGameSoccer === 'select') {
            setTotalGameSoccer?.(listGameSoccer.length)
        } else {
            if (totalGamePrematchSoccer.current)
                setTotalGameSoccer?.(totalGamePrematchSoccer.current)
        }
    }, [listGameSoccer])

    useEffect(() => {
        if (resetBetGame) {
            gGameSoccerByTournamentName()
            updateBetGameReset?.()
        }
    }, [resetBetGame])

    useEffect(() => {
        if (item.key)
            gGameSoccerByTournamentName()
    }, [item])

    useEffect(() => {
        if (loadTournament && loadTournament === item.tournament) {
            gGameSoccerByTournamentName()
            setLoadTournament && setLoadTournament()
        }
    }, [loadTournament])
    // new Date("06/20/2024 21:00 GMT+8")
    return (
        <>

            {
                listGameSoccer && listGameSoccer.map((item, i) =>
                    <LazyLoad height={200} key={i}>
                        <div key={i}>
                            {item.subtractTimeClient > 60000 && (
                                <>
                                    <div className='flex xs:gap-3 sm:gap-0 xs:flex-col sm:flex-row justify-between xs:items-start sm:items-center'>
                                        <div className='flex items-center gap-4'>
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{item.homeTeam.teamName}</span>
                                            </div>
                                            <span className='text-[20px] text-[#D4B962]'>VS</span>
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{item.awayTeam.teamName}</span>
                                            </div>
                                        </div>
                                        <div className='h-6 flex items-center xs:justify-start sm:justify-end gap-2 rounded-xl border-[#FF4A59] border w-fit px-2 lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>
                                            <img src={video} alt="" />
                                            <span className="text-[#FFFFFF] whitespace-nowrap">
                                                {item.showTime}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex gap-1 pt-3 w-full'>
                                        {/* homeRaito w-[57%] flex justify-between */}
                                        <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] w-[57%]'>
                                            <div className='w-full border-b border-[#282D39] py-2'>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1] '>Full time</span>
                                            </div>
                                            <div className='flex items-center justify-around py-2'>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>HDP</span>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>OU</span>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>1x2</span>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>OE</span>
                                            </div>
                                        </div>
                                        <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] w-[43%]'>
                                            <div className='w-full border-b border-[#282D39] py-2'>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1] '>First half</span>
                                            </div>
                                            <div className='flex items-center justify-around py-2'>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>HDP</span>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'>OU</span>
                                                <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base text-[#E4E7F1]'> 1x2</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {item.subtractTimeClient > 60000 && (
                                <div className="gameDetail soccerGame flex flex-col w-full justify-between !p-0">
                                    <SoccerTopV3
                                        idGame={item.id}
                                        homeTeam={item.homeTeam.teamName}
                                        awayTeam={item.awayTeam.teamName}
                                        tournament={tournament}
                                        item={item}
                                        listChooseBet={listChooseBet}
                                        chooseBetSoccer={chooseBetSoccer}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateOdds={checkUpdateOdds}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                </div >
                            )}
                            {/* {item.subtractTimeClient > 60000 && (
                                <div className=" !bg-[#131620] soccerGame flex flex-col w-full justify-between">
                                    <SoccerTopV3
                                        idGame={item.id}
                                        homeTeam={item.homeTeam.teamName}
                                        awayTeam={item.awayTeam.teamName}
                                        tournament={tournament}
                                        item={item}
                                        listChooseBet={listChooseBet}
                                        chooseBetSoccer={chooseBetSoccer}
                                        dateFilterSoccer={dateFilterSoccer}
                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                        checkUpdateOdds={checkUpdateOdds}
                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                        listDateFilterSoccer={listDateFilterSoccer}
                                    />
                                </div >
                            )} */}

                            {/* <div className='bg-white p-[16px]'>
                                <div className='soccerTop p-[10px] columns-3 flex justify-between items-center px-16 !border-0 rounded-sm bg-[#E5ECFF]'>
                                    <div className='flex flex-col items-center w-[180px]'>
                                        <img className='w-[44px] h-[44px]'
                                            src={LogoWithoutText}
                                            alt="NYKnicks" />
                                        <span className='font-bold-GT-America text-[16px]'>
                                            {item.homeTeam.teamName}
                                        </span>
                                    </div>
                                    <div className='flex flex-col justify-center w-[100px] text-center'>
                                        <span className="text-[#BB271A]">
                                            {item.showTime}
                                        </span>
                                        <span className='text-[#BB271A] font-bold-GT-America'>
                                        </span>
                                    </div>
                                    <div className='flex flex-col items-center w-[180px]'>
                                        <img className='w-[44px] h-[44px]' src={LogoWithoutText} alt="WASWizards" />
                                        <span className='font-bold-GT-America text-[16px]'>
                                            {item.awayTeam.teamName}
                                        </span>
                                    </div>
                                </div>
                            </div> */}


                        </div>

                    </LazyLoad>
                )
            }
            {

                checkNoData(listGameSoccer, 'pb-4')

            }
            {/* <SoccerTime
            /> */}

        </>

    );
};

export default GameSoccer_V2;