import OddsSettings from '../Content/Sidebar/OddsSettings';
interface BetSlipTabProps {
    showTabs: boolean;
}
const BetSlipTab: React.FC<BetSlipTabProps> = ({ showTabs }) => {

    return (
        <>
            {
                showTabs ? (
                    <div>
                        <div className='flex flex-col pl-[22px] pt-2 pb-3'>
                            <span className="font-bold-GT-America flex justify-start">Odds Settings</span>
                            <OddsSettings />
                        </div>
                        <span className='flex justify-start pl-[18px] text-left text-13'>By placing a bet, you automatically accept the most recent version of the Terms and Conditions</span>
                    </div >
                ) : ''
            }
        </>

    )
}
export default BetSlipTab