import { Modal, Spin } from "antd"

interface ModalBetsProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void
}
const LoadingModal = ({ showModal, setShowModal }: ModalBetsProps) => {

    return (
        <Modal
            className='canPlayModal loadingModal flex justify-center'
            title={<Spin tip="Loading" size="large" className='size-9 pb-3' />}
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
            closable={false}
            maskClosable={false}
        >
            <p className="text-center text-[#4E7FFF] !text-[13px] !pb-1">Loading</p>
            <p className="!text-[14px]">Please wait for the system to issue an invoice</p>
        </Modal>
    )
}
export default LoadingModal