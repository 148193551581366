/* eslint-disable react-hooks/exhaustive-deps */
import { empty } from "../../../components/imgExport"
// import { Pagination } from "antd";
import { ItemSoccerSettled } from "./Item/ItemSoccerSettled";


interface ItemProp {
    totalSettled?: number
    setTotalSettled?: (value: number) => void
    listBill?: any[]
    chooseGame?: string
    setLimit?: (value: number) => void
    setOffset?: (value: number) => void
    setChooseGame?: (value: string) => void

}

export const Settled: React.FC<ItemProp> = ({
    totalSettled,
    setTotalSettled,
    listBill,
    chooseGame,
    setLimit,
    setOffset,
    setChooseGame,
}) => {
    return (
        <div className="w-full">
            <div className='w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530]'>
                <div className={`xs:px-1 xl:px-6  xl:h-[calc(100vh-140px)]xs:h-[calc(100vh-250px)]`}>
                    {
                        listBill && listBill.length > 0
                            ? (
                                <div className="flex flex-col">
                                    <div className="xs:hidden xl:flex w-full bg-header rounded-tl-lg rounded-tr-lg">
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America ">Code & Time</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[50%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America">Game</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">score</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">odds</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">stake</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">comm</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">w/l</span>
                                        </div>
                                        <div className="flex justify-center py-[7px] w-[5%]">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">Status</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col xs:gap-[18px] xl:gap-0 w-full xs:h-[calc(100vh-270px)]  xl:h-[calc(100vh-185px)] overflow-y-scroll">
                                        {listBill && listBill.map((item: any) => (
                                            <ItemSoccerSettled key={item.id} status={1} item={item} />
                                        ))}
                                    </div>
                                </div>
                            )
                            : (
                                <div className="flex flex-col">
                                    <div className="pt-[120px] flex flex-col items-center gap-3">
                                        <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                        <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                                    </div>
                                </div>
                            )
                    }
                </div>
                {/* <div className="w-full border border-[#263859] bg-[#20273d]">
                    <Pagination align="center" defaultCurrent={1} total={Math.round(totalSettled ? totalSettled / 2 : 1)} className="custom-paginaton" onChange={(e) => setOffset && setOffset((e - 1) * 20)} />
                </div> */}
            </div>
        </div>
    )
}