import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/userSlice';
import toastReducer from './slices/toastSlice';
import loadingReducer from './slices/loadingSlice';
import modalLoginReducer from './slices/modalLoginSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["user", "location", "modalLogin"]
}

const rootReducer = combineReducers({
    user: userReducer,
    notifications: toastReducer,
    loading: loadingReducer,
    modalLogin: modalLoginReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});
export const persistor = persistStore(store);
export default store;