import React, { useState } from 'react';
import '../GameList/gameList.css'
import { bg_checked, checked, LogoWithoutText, video_2 } from '../../../../../components/imgExport';
import { LockOutlined } from '@ant-design/icons';
import { ChooseBet } from '../../../../../api/betting/bettingApi';
import { InfoBetMGM } from '../../../../../api/homePage/homePage_V2Api';
import { covertDataOptionMarkets } from '../../../../../until';
import { ICON_TEAM } from '../../../../../env';
interface GameDetailProps {
  sport?: string
  tournament: string
  item: InfoBetMGM
  listChooseBet: ChooseBet[]
  chooseBet?: (
    tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live?: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, homeLiveScore?: number, awayLiveScore?: number, dateEventServer?: string, order?: number
  ) => void
  decimal: string
  inputFilter?: string
}

function formatDate(UTC: string) {
  let d = new Date(UTC).toLocaleDateString()
  return d
}

function formatTime(UTC: string) {
  let d = new Date(UTC).toLocaleTimeString()
  return d
}

const GameDeTail_V2: React.FC<GameDetailProps> = ({
  sport, tournament, item, listChooseBet, decimal, inputFilter,
  chooseBet,

}) => {
  const [timeGame, setTimeGame] = useState<number>(1)
  // const [gameLine, setGameLine] = useState<number>(1)
  const { startDate, participants, optionMarkets } = item
  var startingPitcherHomeTeam = item.addons?.fixtureStatistics?.startingPitcher?.homeTeam ? item.addons?.fixtureStatistics?.startingPitcher?.homeTeam.toString().split(" ") : ""
  var startingPitcherAwayTeam = item.addons?.fixtureStatistics?.startingPitcher?.awayTeam ? item.addons?.fixtureStatistics?.startingPitcher?.awayTeam.toString().split(" ") : ""


  var startingPitcherHomeTeamFinal: any
  var startingPitcherAwayTeamFinal: any
  for (let index = 0; index < startingPitcherHomeTeam.length; index++) {
    if (index === 0) {
      startingPitcherHomeTeamFinal = startingPitcherHomeTeam[index][0] + "."
    } else {
      startingPitcherHomeTeamFinal = startingPitcherHomeTeamFinal + " " + startingPitcherHomeTeam[index]
    }
  }

  for (let index = 0; index < startingPitcherAwayTeam.length; index++) {
    if (index === 0) {
      startingPitcherAwayTeamFinal = startingPitcherAwayTeam[index][0] + "."
    } else {
      startingPitcherAwayTeamFinal = startingPitcherAwayTeamFinal + " " + startingPitcherAwayTeam[index]
    }
  }

  // const { games, startDate, participants, optionMarkets } = item
  if (optionMarkets.length === 0) {
    // const { spread, totals, moneyLine } = covertDataGames(games)  
  } else {

  }
  const { spread, totals, moneyLine, FHspread, FHtotals, FHmoneyLine, spread5Innings, totals5Innings, moneyLine5Innings } = covertDataOptionMarkets(optionMarkets)
  var spreadRunLine: any
  if (!spread) {
    spreadRunLine = optionMarkets.filter((market) => market.name.value === "Run Line Spread")[0]
  }

  const checkSelect = (team: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money") => {
    const isDuplicate = listChooseBet.some(
      (bet) => bet.teamChoose?.indexOf(team) !== -1 && bet.lineBetting === lineBetting && bet.dateEvent === (formatDate(startDate) + "|" + formatTime(startDate))
    );
    return isDuplicate ? "!border-[#D4B962] icon-selected" : ""
  }

  const handelBet = (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", id?: number | string, order?: number) => {
    const game = participants[1].name.value + " / " + participants[0].name.value
    chooseBet?.(tournament, game, teamChoose, scoreChoose, lineBetting, (formatDate(startDate) + "|" + formatTime(startDate)), false, item.id, item.id, id, 0, 0, startDate.toString(), order)
  }
  return (
    <div className={` flex flex-col gap-3 items-center w-full justify-between py-2 border-b border-b-[#1D212D] hover:!bg-[#181c2a] ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
    >

      <div className='flex justify-between gap-2 w-full'>
        <div className=' xl:w-[160px] xl2:w-[220px] xl3:w-[220px]  xs:hidden xl:flex items-center justify-between px-2 '>
          <div className='flex items-center gap-2'>
            <img src={video_2} alt="" />
            <span className="fw-[500] xs:text-xs md:text-sm text-[#FFFFFF]">{formatDate(startDate)}</span>
          </div>
          <div className='w-[10px] border border-[#2B303D]'></div>
          <span className='text-[#FFFFFF]  xs:text-xs md:text-sm'> {formatTime(startDate)}</span>
        </div>
        <div className=' xl:w-[160px] xl2:w-[220px]  rounded-xl border border-[#2B313E] bg-[#202531] xs:flex xl:hidden items-center justify-between gap-2 px-2 '>
          <img src={video_2} alt="" />
          <div className='flex xs:flex-col md:flex-row md:items-center xs:gap-0 md:gap-2'>
            <span className="fw-[500] xs:text-[10px] md:text-sm text-[#FFFFFF]">{formatDate(startDate)}</span>
            <div className='xs:w-0 md:w-[10px] xs:h-0 md:h-[2px] border border-[#2B303D]'></div>
            <span className='text-[#FFFFFF]  xs:text-[10px] md:text-sm'> {formatTime(startDate)}</span>
          </div>
        </div>
        {(sport === "MLB") && (spread5Innings || totals5Innings || moneyLine5Innings) && (
          <div className="rounded-xl border border-[#2B313E] bg-[#202531] xs:flex xl:hidden gap-2 items-center justify-between px-1">
            <span
              className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 1 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
              onClick={() => setTimeGame(1)}
            >
              Full Game
            </span>
            <span
              className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 2 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
              onClick={() => setTimeGame(2)}
            >
              First 5 Innings
            </span>
          </div>
        )}
        {(sport === "NFL" || sport === "NBA") && (FHspread || FHtotals || FHmoneyLine) && (
          <div className="rounded-xl border border-[#2B313E] bg-[#202531] xs:flex xl:hidden gap-2 items-center justify-between px-1">
            <span
              className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 1 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
              onClick={() => setTimeGame(1)}
            >
              Full Game
            </span>
            <span
              className={`xs:text-[10px] md:text-sm whitespace-nowrap ${timeGame === 2 ? 'text-[#D4B962]' : 'text-[#FFFFFF]'}`}
              onClick={() => setTimeGame(2)}
            >
              1st Half
            </span>
          </div>
        )}

        <div className='bg-header rounded-tl-[6px] rounded-tr-[6px] xs:w-[50%]  xl:w-[250px]  xl3:w-[400px] xl:hidden  xs:grid grid-cols-3 gap-2 py-2'>
          <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
          <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
          <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
        </div>
      </div>
      {/* <div className='xs:flex xl:hidden gap-3  w-full justify-end'>
          <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2  xl:w-[400px]'>
            <div className='w-full border-b border-[#282D39] py-2'>
              <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>Full time</span>
            </div>
            <div className='grid grid-cols-3 gap-2 py-2'>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
            </div>
          </div>

          <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2   xl:w-[400px]'>
            <div className='w-full border-b border-[#282D39] py-2'>
              <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>First half</span>
            </div>
            <div className='grid grid-cols-3 gap-2 py-2'>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
              <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
            </div>
          </div>
        </div> */}
      {item.subtractTimeClient < 60000 ?
        <div className="gameRatio flex flex-col gap-3 ">
          <div className=" grid grid-cols-3 gap-2">
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
          </div>

          <div className=" grid grid-cols-3 gap-2">
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
            <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative `}>
              <span className='!text-[#E4E7F1]'><LockOutlined /></span>
            </div>
          </div>
        </div>
        :
        <div className='flex justify-between gap-2 xs:pr-0 xl:pr-2 w-full'>
          <div className="gameTime xs:w-1/2 xl:w-auto"
          // onClick={() => navigator("/detailPage")}
          >
            <div className='flex  flex-col justify-between items-start  gap-2'>

              <div className='flex  flex-col items-start gap-3'>

                <div className='flex items-center justify-between md:w-[160px] xl:w-[160px] xl2:w-[190px]  '>
                  <div className='flex items-center gap-2'>
                    <img
                      className='w-[30px] h-[30px]'
                      // src={LogoWithoutText}
                      src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                      alt="team1"
                      onError={(e) => {
                        e.currentTarget.src = LogoWithoutText;
                      }} />
                    <div className='flex flex-col items-start'>
                      <span className='flex item-start xs:text-[10px] md:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                      <div className='flex items-start'>
                        <span className='text-sm text-[#D4B962] fw-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-base'>{startingPitcherAwayTeamFinal}</span>
                      </div>
                    </div>
                  </div>

                  <b className='pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                </div>


                {/* <span className='text-[#D4B962] md:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base pl-2'>VS</span> */}
                <div className='flex items-center justify-between md:w-[160px] xl:w-[160px] xl2:w-[190px] xl3:w-[190px] '>
                  <div className='flex items-center gap-2'>
                    <img
                      className=' w-[30px] h-[30px]'
                      // src={LogoWithoutText}
                      src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                      alt="team1"
                      onError={(e) => {
                        e.currentTarget.src = LogoWithoutText;
                      }} />
                    <div className='flex flex-col'>
                      <span className='flex item-start xs:text-[10px] md:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                      <div className='flex items-start'>
                        <span className='text-sm text-[#D4B962] fw-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-base'>{startingPitcherHomeTeamFinal}</span>
                      </div>
                    </div>
                  </div>

                  <b className='pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                </div>

              </div>

            </div>
          </div>
          <div className='w-full flex justify-end gap-2 xs:w-[50%]  xl:w-[500px]  xl3:w-[800px]'>
            {/* Full time */}
            <div className={`${window.outerWidth >= 1365 ? "flex" : (timeGame === 1 ? "flex" : " hidden")} gameRatio flex flex-col gap-3 w-full`}>
              <div className=" grid grid-cols-3 gap-2">
                {spread?.status === 'Visible' || spreadRunLine?.status === 'Visible' ? (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "Spread")} hover:!border-[#D4B962]`}
                  >
                    {
                      spread?.options[0]?.status === 'Visible' && spread?.options[0]?.attr ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[0].name.value, spread?.options[0]?.attr + " " + (decimal !== 'American' ? spread?.options[0]?.price?.odds : spread?.options[0]?.price?.americanOdds), "Spread", spread?.options[0]?.id, 0)}
                        >
                          <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread?.options[0]?.attr}`}</span>
                          <span><b className='text-sm !text-[#D4B962] !font-normal'>{`${(decimal !== 'American' ? spread?.options[0]?.price?.odds.toFixed(2) : (Number(spread?.options[0]?.price?.americanOdds) > 0 ? `+${spread?.options[0]?.price?.americanOdds}` : spread?.options[0]?.price?.americanOdds))}`}</b></span>
                        </div> :
                        spreadRunLine?.options[0]?.status === 'Visible' && spreadRunLine?.options[0]?.attr ?
                          <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                            onClick={() => handelBet?.(participants[0].name.value, spreadRunLine?.options[0]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.options[0]?.price?.odds : spreadRunLine?.options[0]?.price?.americanOdds), "Spread", spreadRunLine?.options[0]?.id, 0)}
                          >
                            <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spreadRunLine?.options[0]?.attr}`}</span>
                            <span><b className='!text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? spreadRunLine?.options[0]?.price?.odds.toFixed(2) : (Number(spreadRunLine?.options[0]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.options[0]?.price?.americanOdds}` : spreadRunLine?.options[0]?.price?.americanOdds))}`}</b></span>
                          </div> :
                          <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[0].name.value, "Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "Spread")}`}
                  >
                    <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}

                {totals?.status === 'Visible' ? (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "Total")} hover:!border-[#D4B962]`}
                  >
                    {
                      totals?.options[0]?.status === 'Visible' && totals?.options[0]?.name.value ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[0].name.value, totals?.options[0]?.name.value + " " + (decimal !== 'American' ? totals?.options[0]?.price.odds : totals?.options[0]?.price.americanOdds), "Total", totals?.options[0]?.id, 0)}
                        >
                          <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals?.options[0]?.name.value}`}</span>
                          <span><b className='text-[#D4B962] text-sm !font-normal'>{`${(decimal !== 'American' ? totals?.options[0]?.price.odds.toFixed(2) : (Number(totals?.options[0]?.price.americanOdds) > 0 ? `+${totals?.options[0]?.price.americanOdds}` : totals?.options[0]?.price.americanOdds))}`}</b></span>
                        </div> :
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[0].name.value, "Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${checkSelect(participants[0].name.value, "Total")}`}
                  >
                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}

                {moneyLine?.status === 'Visible' ? (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "Money")} hover:!border-[#D4B962]`}
                  >
                    {
                      moneyLine?.options[0]?.status === 'Visible' && moneyLine?.options[0]?.price?.odds ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine?.options[0]?.price.odds?.toString() : moneyLine?.options[0]?.price.americanOdds?.toString()), "Money", moneyLine?.options[0]?.id, 0)}
                        >
                          <b className='text-[#D4B962] text-sm !font-normal'>{(decimal !== 'American' ? moneyLine?.options[0]?.price.odds.toFixed(2) : (Number(moneyLine?.options[0]?.price.americanOdds) > 0 ? `+${moneyLine?.options[0]?.price.americanOdds}` : moneyLine?.options[0]?.price.americanOdds))}</b>
                        </div> :
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[0].name.value, "Money").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "Money")}`}
                  >
                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}


              </div>

              <div className=" grid grid-cols-3 gap-2">
                {spread?.status === 'Visible' || spreadRunLine?.status === 'Visible' ? (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Spread")} hover:!border-[#D4B962]`}
                  >
                    {
                      spread?.options[1]?.status === 'Visible' && spread?.options[1]?.attr ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[1].name.value, spread?.options[1]?.attr + " " + (decimal !== 'American' ? spread?.options[1]?.price?.odds : spread?.options[1]?.price?.americanOdds), "Spread", spread?.options[1]?.id, 1)}
                        >
                          <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread?.options[1]?.attr}`}</span>
                          <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? spread?.options[1]?.price?.odds.toFixed(2) : (Number(spread?.options[1]?.price?.americanOdds) > 0 ? `+${spread?.options[1]?.price?.americanOdds}` : spread?.options[1]?.price?.americanOdds))}`}</b></span>
                        </div> :
                        spreadRunLine?.options[1]?.status === 'Visible' && spreadRunLine?.options[1]?.attr ?
                          <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                            onClick={() => handelBet?.(participants[1].name.value, spreadRunLine?.options[1]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.options[1]?.price?.odds : (Number(spreadRunLine?.options[1]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.options[1]?.price?.americanOdds}` : spreadRunLine?.options[1]?.price?.americanOdds)), "Spread", spreadRunLine?.options[1]?.id, 1)}
                          >
                            <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spreadRunLine?.options[1]?.attr}`}</span>
                            <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? spreadRunLine?.options[1]?.price?.odds.toFixed(2) : spreadRunLine?.options[1]?.price?.americanOdds)}`}</b></span>
                          </div> :
                          <span className='text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[1].name.value, "Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Spread")}`}
                  >
                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}

                {totals?.status === 'Visible' ? (
                  <div
                    className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Total")} hover:!border-[#D4B962]`}
                  >
                    {
                      totals?.options[1]?.status === 'Visible' && totals?.options[1]?.name.value ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[1].name.value, totals?.options[1]?.name.value + " " + (decimal !== 'American' ? totals?.options[1]?.price.odds : totals?.options[1]?.price.americanOdds), "Total", totals?.options[1]?.id, 1)}
                        >
                          <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals?.options[1]?.name.value}`}</span>
                          <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? totals?.options[1]?.price.odds.toFixed(2) : (Number(totals?.options[1]?.price.americanOdds) > 0 ? `+${totals?.options[1]?.price.americanOdds}` : totals?.options[1]?.price.americanOdds))}`}</b></span>
                        </div> :
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[1].name.value, "Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Total")}`}
                  >
                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}

                {moneyLine?.status === 'Visible' ? (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Money")} hover:!border-[#D4B962]`}
                  >
                    {
                      moneyLine?.options[1]?.status === 'Visible' && moneyLine?.options[1]?.price?.odds ?
                        <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                          onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine?.options[1]?.price?.odds?.toString() : moneyLine?.options[1]?.price?.americanOdds?.toString()), "Money", moneyLine?.options[1]?.id, 1)}
                        ><b className='text-[#D4B962] text-sm !font-normal '>{(decimal !== 'American' ? moneyLine?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLine?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLine?.options[1]?.price?.americanOdds}` : moneyLine?.options[1]?.price?.americanOdds))}</b>
                        </div> :
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                    }
                    {checkSelect(participants[1].name.value, "Money").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                  </div>
                ) : (
                  <div
                    className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "Money")}`}
                  >
                    <span className='text-[#E4E7F1]'><LockOutlined /></span>
                  </div>
                )}
              </div>
            </div>
            {/* Firs half */}
            {(sport === "NFL" || sport === "NBA") && (
              <div className={`${window.outerWidth >= 1365 ? "flex" : (timeGame === 1 ? "hidden" : "flex w-full")} flex-col  xl:w-[500px]  xl3:w-[800px]`}>
                <div className="gameRatio flex flex-col gap-3 ">
                  <div className="grid grid-cols-3 gap-2">
                    {FHspread?.status === 'Visible' ? (
                      <div
                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "First Half Spread")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHspread?.options[0]?.status === 'Visible' && FHspread?.options[0]?.attr ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, FHspread?.options[0]?.attr + " " + (decimal !== 'American' ? FHspread?.options[0]?.price?.odds : FHspread?.options[0]?.price?.americanOdds), "First Half Spread", FHspread?.options[0]?.id, 0)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHspread?.options[0]?.attr}`}</span>
                              <span><b className='!text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? FHspread?.options[0]?.price?.odds.toFixed(2) : (Number(FHspread?.options[0]?.price?.americanOdds) > 0 ? `+${FHspread?.options[0]?.price?.americanOdds}` : FHspread?.options[0]?.price?.americanOdds))}`}</b></span>
                            </div> :
                            <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First Half Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First Half Spread")}`}
                      >
                        <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {FHtotals?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First Half Total")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHtotals?.options[0]?.status === 'Visible' && FHtotals?.options[0]?.name.value ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, FHtotals?.options[0]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[0]?.price.odds : FHtotals?.options[0]?.price.americanOdds), "First Half Total", FHtotals?.options[0]?.id, 0)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHtotals?.options[0]?.name.value}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal'>{`${(decimal !== 'American' ? FHtotals?.options[0]?.price.odds.toFixed(2) : (Number(FHtotals?.options[0]?.price.americanOdds) > 0 ? `+${FHtotals?.options[0]?.price.americanOdds}` : FHtotals?.options[0]?.price.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First Half Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${checkSelect(participants[0].name.value, "First Half Total")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {FHmoneyLine?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First Half Money")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHmoneyLine?.options[0]?.status === 'Visible' && FHmoneyLine?.options[0]?.price?.odds ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds?.toString() : FHmoneyLine?.options[0]?.price.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[0]?.id, 0)}
                            >
                              <b className='text-[#D4B962] text-sm !font-normal'>{(decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds.toFixed(2) : (Number(FHmoneyLine?.options[0]?.price.americanOdds) > 0 ? `+${FHmoneyLine?.options[0]?.price.americanOdds}` : FHmoneyLine?.options[0]?.price.americanOdds))}</b>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First Half Money").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First Half Money")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}


                  </div>

                  <div className=" grid grid-cols-3 gap-2">
                    {FHspread?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "First Half Spread")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHspread?.options[1]?.status === 'Visible' && FHspread?.options[1]?.attr ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, FHspread?.options[1]?.attr + " " + (decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), "First Half Spread", FHspread?.options[1]?.id, 1)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHspread?.options[1]?.attr}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? FHspread?.options[1]?.price?.odds.toFixed(2) : (Number(FHspread?.options[1]?.price?.americanOdds) > 0 ? `+${FHspread?.options[1]?.price?.americanOdds}` : FHspread?.options[1]?.price?.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First Half Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First Half Spread")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {FHtotals?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First Half Total")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHtotals?.options[1]?.status === 'Visible' && FHtotals?.options[1]?.name.value ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, FHtotals?.options[1]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[1]?.price.odds : FHtotals?.options[1]?.price.americanOdds), "First Half Total", FHtotals?.options[1]?.id, 1)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${FHtotals?.options[1]?.name.value}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? FHtotals?.options[1]?.price.odds.toFixed(2) : (Number(FHtotals?.options[1]?.price.americanOdds) > 0 ? `+${FHtotals?.options[1]?.price.americanOdds}` : FHtotals?.options[1]?.price.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First Half Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First Half Total")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {FHmoneyLine?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First Half Money")} hover:!border-[#D4B962]`}
                      >
                        {
                          FHmoneyLine?.options[1]?.status === 'Visible' && FHmoneyLine?.options[1]?.price?.odds ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds?.toString() : FHmoneyLine?.options[1]?.price?.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[1]?.id, 1)}
                            ><b className='text-[#D4B962] text-sm !font-normal '>{(decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds.toFixed(2) : (Number(FHmoneyLine?.options[1]?.price?.americanOdds) > 0 ? `+${FHmoneyLine?.options[1]?.price?.americanOdds}` : FHmoneyLine?.options[1]?.price?.americanOdds))}</b>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First Half Money").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First Half Money")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Firs 5 Innings */}
            {(sport === "MLB") && (
              <div className={`${window.outerWidth >= 1365 ? "flex" : (timeGame === 1 ? "hidden" : "flex w-full")} flex-col  xl:w-[500px]  xl3:w-[800px]`}>
                <div className="gameRatio flex flex-col gap-3 ">
                  <div className="grid grid-cols-3 gap-2">
                    {spread5Innings?.status === 'Visible' ? (
                      <div
                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[0].name.value, "First 5 Innings Spread")} hover:!border-[#D4B962]`}
                      >
                        {
                          spread5Innings?.options[0]?.status === 'Visible' && spread5Innings?.options[0]?.attr ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, spread5Innings?.options[0]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), "First 5 Innings Spread", spread5Innings?.options[0]?.id, 0)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread5Innings?.options[0]?.attr}`}</span>
                              <span><b className='!text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[0]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[0]?.price?.americanOdds}` : spread5Innings?.options[0]?.price?.americanOdds))}`}</b></span>
                            </div> :
                            <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First 5 Innings Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First 5 Innings Spread")}`}
                      >
                        <span className='!text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {totals5Innings?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First 5 Innings Total")} hover:!border-[#D4B962]`}
                      >
                        {
                          totals5Innings?.options[0]?.status === 'Visible' && totals5Innings?.options[0]?.name.value ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, totals5Innings?.options[0]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[0]?.price.odds : totals5Innings?.options[0]?.price.americanOdds), "First 5 Innings Total", totals5Innings?.options[0]?.id, 0)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals5Innings?.options[0]?.name.value}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal'>{`${(decimal !== 'American' ? totals5Innings?.options[0]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[0]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[0]?.price.americanOdds}` : totals5Innings?.options[0]?.price.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First 5 Innings Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md ${checkSelect(participants[0].name.value, "First 5 Innings Total")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {moneyLine5Innings?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First 5 Innings Money")} hover:!border-[#D4B962]`}
                      >
                        {
                          moneyLine5Innings?.options[0]?.status === 'Visible' && moneyLine5Innings?.options[0]?.price?.odds ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds?.toString() : moneyLine5Innings?.options[0]?.price.americanOdds?.toString()), "First 5 Innings Money", moneyLine5Innings?.options[0]?.id, 0)}
                            >
                              <b className='text-[#D4B962] text-sm !font-normal'>{(decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds.toFixed(2) : (Number(moneyLine5Innings?.options[0]?.price.americanOdds) > 0 ? `+${moneyLine5Innings?.options[0]?.price.americanOdds}` : moneyLine5Innings?.options[0]?.price.americanOdds))}</b>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[0].name.value, "First 5 Innings Money").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[0].name.value, "First 5 Innings Money")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}


                  </div>

                  <div className=" grid grid-cols-3 gap-2">
                    {spread5Innings?.status === 'Visible' ? (
                      <div
                        className={`  bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  flex items-center flex-col rounded-md relative h-10 ${checkSelect(participants[1].name.value, "First 5 Innings Spread")} hover:!border-[#D4B962]`}
                      >
                        {
                          spread5Innings?.options[1]?.status === 'Visible' && spread5Innings?.options[1]?.attr ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, spread5Innings?.options[1]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), "First 5 Innings Spread", spread5Innings?.options[1]?.id, 1)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${spread5Innings?.options[1]?.attr}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[1]?.price?.americanOdds}` : spread5Innings?.options[1]?.price?.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First 5 Innings Spread").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First 5 Innings Spread")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {totals5Innings?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First 5 Innings Total")} hover:!border-[#D4B962]`}
                      >
                        {
                          totals5Innings?.options[1]?.status === 'Visible' && totals5Innings?.options[1]?.name.value ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, totals5Innings?.options[1]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[1]?.price.odds : totals5Innings?.options[1]?.price.americanOdds), "First 5 Innings Total", totals5Innings?.options[1]?.id, 1)}
                            >
                              <span className='text-[#E4E7F1] xs:text-[10px] md:text-xs  '>{`${totals5Innings?.options[1]?.name.value}`}</span>
                              <span><b className='text-[#D4B962] text-sm !font-normal '>{`${(decimal !== 'American' ? totals5Innings?.options[1]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[1]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[1]?.price.americanOdds}` : totals5Innings?.options[1]?.price.americanOdds))}`}</b></span>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First 5 Innings Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First 5 Innings Total")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}

                    {moneyLine5Innings?.status === 'Visible' ? (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First 5 Innings Money")} hover:!border-[#D4B962]`}
                      >
                        {
                          moneyLine5Innings?.options[1]?.status === 'Visible' && moneyLine5Innings?.options[1]?.price?.odds ?
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer'
                              onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds?.toString() : moneyLine5Innings?.options[1]?.price?.americanOdds?.toString()), "First 5 Innings Money", moneyLine5Innings?.options[1]?.id, 1)}
                            ><b className='text-[#D4B962] text-sm !font-normal '>{(decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLine5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLine5Innings?.options[1]?.price?.americanOdds}` : moneyLine5Innings?.options[1]?.price?.americanOdds))}</b>
                            </div> :
                            <span className='text-[#E4E7F1]'><LockOutlined /></span>
                        }
                        {checkSelect(participants[1].name.value, "First 5 Innings Total").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                      </div>
                    ) : (
                      <div
                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full pl-2 flex items-center flex-col h-10 rounded-md relative ${checkSelect(participants[1].name.value, "First 5 Innings Money")}`}
                      >
                        <span className='text-[#E4E7F1]'><LockOutlined /></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>
      }

    </div >

  )
}

export default GameDeTail_V2;