/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from "react-router-dom";
import './App.css';
import axiosClient from './api/axiosClient';
import LoadingGlobal from './components/Loading/Loader';
// import ToastGlobal from './components/ToastGlobal';
import Router from './routes/Router';
import { useEffect, useRef } from "react";
import './theme/responsive.css'
// import "antd/dist/reset.css"
import tokenService from './services/token';

function App() {
  const location = useLocation();
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentPath = location.pathname;
  axiosClient.interceptors.response.use(
    (response) => response,
    // (response) => response,
    (error) => {
      if ((error.response) && (error.response.status === 403 || error.response.status === 401)) {
        if (location.pathname !== "/login") {
          if (error.response.data.message === "Account is logged in on another device") {
            setTimeout(() => {
              tokenService.removeToken();
              window.location.href = "/login";
            }, 3000)
          } else {
            //clear token
            tokenService.removeToken()
            window.location.href = "/login";
          }
        }
        return error?.response?.data;
      } else if ((error.response) && (error.response.status === 406)) {
        window.location.href = "/firstlogin";
      }
    }
  );

  const timerRef = useRef<number | undefined>(undefined);
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      return
    }
    timerRef.current = window.setTimeout(() => {
      // Kích hoạt chế độ sleep
      activateSleepMode();
    }, 1800000); // 1 giờ
  };

  const activateSleepMode = () => {
    // alert('Ứng dụng sẽ vào chế độ ngủ do không có thao tác trong 1 giờ.');
    window.location.reload();
    // Thực hiện các hành động cần thiết để vào chế độ sleep
    // Ví dụ: đóng phiên làm việc, chuyển đến trang login, hoặc tắt các chức năng không cần thiết
  };

  useEffect(() => {
    // Đặt lại timer khi phát hiện có hoạt động của người dùng
    const handleUserActivity = () => {
      resetTimer();
    };

    // Lắng nghe các sự kiện người dùng
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);
    window.addEventListener('touchmove', handleUserActivity);
    window.addEventListener('touchend', handleUserActivity);

    // Thiết lập timer lần đầu tiên
    resetTimer();

    // Xóa các sự kiện và timer khi component bị unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
      window.removeEventListener('touchmove', handleUserActivity);
      window.removeEventListener('touchend', handleUserActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <ToastGlobal /> */}
      <LoadingGlobal />
      <Router />
    </>
  );
}

export default App;
