import React, { useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio, Space } from 'antd';

const OddsSettings: React.FC = () => {
    const [value, setValue] = useState(1);

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    return (
        <Radio.Group className='flex justify-start' onChange={onChange} value={value}>
            <Space direction="vertical">
                <Radio className='!flex justify-start' value={1}>
                    <div className='flex flex-col justify-start'>
                        <span className='text-left pt-5 text-13'>Accept any odds</span>
                        <span className='text-[#D5D5D5] text-13'>Fastest live betting experience</span>
                    </div>
                </Radio>
                <Radio className='!flex justify-start pb-1 text-13' value={2}>Accept higher odds</Radio>
                <Radio className='!flex justify-start text-13' value={3}>Dont accept odds changes</Radio>
            </Space>
        </Radio.Group>
    );
};

export default OddsSettings;