import { useRef } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (func: any, delay: number) => {
    const timeout = useRef<any>();
    return function executedFunc(...args: any) {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            func(...args);
            timeout.current = null;
        }, delay);
    };
}
