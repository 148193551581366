import React, { useState } from 'react';
import { Dropdown, Tabs } from 'antd';
import type { MenuProps, TabsProps } from 'antd';
import { edit, lock_open, Logo_KB_3, logoKB_2, menu_icon, menu_open, menuser_circle_whiteu, search, start, user_circle } from '../../../../../components/imgExport';
import '../../stylesMainNavi.css'
import { DrawerLeftMenu } from './Drawer/DrawerLeftMenu';
import { InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from '../../../../../api/betting/bettingApi';
import { itemMenu } from './HeadNavi';
import { ModalChangePassWord } from '../../../../../components/HeaderTabNew/Modal/ModalChangePassWord';
import { ModalChangeUserName } from '../../../../../components/HeaderTabNew/Modal/ModalChangeUserName';


interface Props {

  keyBetTabs: string
  listAddStrightBetsSlip?: InfoStrightBetsSlipTMP[]
  listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
  openDrawer?: boolean
  menu: itemMenu[]
  tournaments: itemMenu

  setTournaments: (value: itemMenu) => void
  closeDrawer?: (value: boolean) => void
  handleOpenDrawerBestSlip?: (value: boolean) => void

}

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Sports',
  },
  {
    key: '2',
    label: 'Live in Game',
  },
];

const tabMargin = 30;
const tabBarGutter = (items.length - 1) * tabMargin;

const HeadTabs: React.FC<Props> = ({
  listAddStrightBetsSlip, keyBetTabs, listParlayBetsSlip, menu, tournaments,
  handleOpenDrawerBestSlip, setTournaments
}) => {
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false)
  const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false)
  const [openModalChangeUserName, setOpenModalChangeUserName] = useState<boolean>(false)
  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: <span className="text-sm text-[#C1C9E1]">Setting</span>,
      children: [
        {
          key: '1-1',
          label: <span
            className="text-sm text-[#C1C9E1] hover:text-[#D4B962] flex items-center gap-3 pb-3"
            onClick={() => setOpenModalChangeUserName(true)}
          >
            <img src={edit} alt="" />
            Change username
          </span>,
        },
        {
          key: '1-2',
          label: <span
            className="text-sm text-[#C1C9E1] hover:text-[#D4B962] flex items-center gap-3"
            onClick={() => setOpenModalChangePass(true)}
          >
            <img src={lock_open} alt="" />
            Change password
          </span>,
        },
      ],
    },
  ];
  return (
    <div className="xs:relative xs:px-6 xl:px-0 xs:flex xs:justify-between xl:flex xl:items-center xl:justify-center  xl:border-b xl:border-b-[#202531] xs:border-0 bg-[#131620] cursor-pointer">
      <DrawerLeftMenu
        openDrawer={openDrawerMenu}
        menu={menu}
        tournaments={tournaments}
        setTournaments={setTournaments}
        closeDrawer={() => setOpenDrawerMenu(false)}
      />
      <ModalChangePassWord
        isOpen={openModalChangePass}
        closeModal={() => setOpenModalChangePass(false)}
      />
      <ModalChangeUserName
        isOpen={openModalChangeUserName}
        closeModal={() => setOpenModalChangeUserName(false)}
      />
      <img src={logoKB_2} alt="Logo" className='w-[48px] h-[48px] xs:block xl:hidden' onClick={() => { window.location.href = '/Soccer_V2' }} />
      <img src={Logo_KB_3} alt="Logo" className='w-[110px] h-[44px] xs:hidden xl:block' onClick={() => { window.location.href = '/Soccer_V2' }} />
      <div className="xs:flex xs:items-center xs:gap-3 xl:hidden">
        <Dropdown menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }} >
          <img src={menuser_circle_whiteu} alt="" />
        </Dropdown>

        <img src={menu_icon} alt="" className='xs:block xl:hidden' onClick={() => setOpenDrawerMenu(true)} />
      </div>
      <div className='hidden w-[173px] mr-[28px] ml-[28px]'>

        {/* <Tabs
          className="headTab"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          tabBarGutter={tabBarGutter}
        /> */}
        {/*  */}
      </div>
      <div className='hidden'>
        <img src={search} alt="Search" />
      </div>

    </div>
  )
}




export default HeadTabs;
