/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'antd';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerMoreTabNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { CaretRightOutlined } from '@ant-design/icons';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { bg_checked, checked } from '../../../../../components/imgExport';
interface Props {
    totalGame?: number
    correctScoreFH: any[]
    correctScoreFHOld?: Odd[]
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const StatusBarSoccerCorrectScoreFH: React.FC<Props> = ({
    correctScoreFH, correctScoreFHOld, totalGame, homeTeam, awayTeam, homeScore, awayScore, listChooseBet, listAddStrightBetsSlipSoccer, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip, updateOdds }) => {
    const odd = useRef<any[]>([correctScoreFH])
    const oddOld = useRef<any[]>([correctScoreFHOld])
    const updateArrayOdds = async () => {
        correctScoreFH.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemState, setItemState] = useState<any[]>([correctScoreFH]);
    useEffect(() => {
        setItemState(correctScoreFH)
        odd.current = correctScoreFH
        oddOld.current = correctScoreFHOld ? correctScoreFHOld : correctScoreFH
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correctScoreFH])

    // useEffect(() => {
    //     setTimeout(() => {
    //         oddOld.current = odd.current
    //     }, 3000);
    // }, [itemState])
    return (
        <>


            {/* <Space className='w-[40%] justify-center pr-[12px] flex items-center text-nowrap'>
                                Double Chance
                            </Space> */}
            {itemState && itemState.length > 0 && (
                <div className='flex gap-2 w-full'>
                    <div className='flex flex-col NFLstatus w-full'>
                        <div className='flex items-center flex-col'>
                            <div className='bg-[#1E212B] rounded-4 flex flex-col xs:mt-2 md:mt-6 pr-[18px] rounded-[6px] soccerBar w-full p-2'>
                                <div className='flex w-full justify-center gap-2 topStatus'>
                                    <Space className='w-full justify-center flex items-center text-nowrap !text-[#E4E7F1] !text-sm !font-bold-GT-America'>
                                        First Half Correct Score
                                    </Space>
                                </div>

                            </div>

                            <div className='grid xs:grid-cols-6 md:grid-cols-8 mt-2 w-full gap-1'>
                                {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.map((item: any, index: number) => {
                                    // if (item?.option !== "O" && item?.option !== "AOS")
                                    return <div className='flex flex-col hdp' key={index}>
                                        <div className={`justify-center w-full flex items-center`}>
                                            <div className={`border border-[#202531]  flex flex-col justify-between items-center w-full  relative hover:!border-[#D4B962] cursor-pointer bg-[#20273D] rounded-md ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[index]?.price, oddOld.current?.[0]?.prices?.[index]?.price,
                                                () => (listChooseBet && odd.current?.[0]?.prices?.[index]?.price !== oddOld.current?.[0]?.prices?.[index]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === item?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, item?.option + " " + item.price, item?.option, homeScore, awayScore) : ""),
                                                dateFilterSoccer,
                                                listDateFilterSoccer
                                            )} ${checkSelectSoccerMoreTabNew_V2(item?.option, index, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "First Half Correct Score")}`}
                                                onClick={() => chooseBetTop?.(
                                                    index, itemState[0].id, homeTeam + " / " + awayTeam, item?.option + " " + item.price, item?.option
                                                )}
                                            >
                                                <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-xs'>{item?.option}</p>
                                                <p><b className='lg:text-sm xl:text-sm !font-[400] text-[#D4B962]'>{Number(item?.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[index]?.price, oddOld.current?.[0]?.prices?.[index]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                                {checkSelectSoccerMoreTabNew_V2(item?.option, index, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "First Half Correct Score").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default StatusBarSoccerCorrectScoreFH;