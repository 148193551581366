export const HeaderItem: React.FC = () => {
    return (
        <div className="xs:hidden xl:flex w-full bg-header rounded-tl-lg rounded-tr-lg">
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[15%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America ">Code & Time</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[50%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America">Game</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[10%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">score</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">odds</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">stake</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">comm</span>
            </div>
            <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[5%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">w/l</span>
            </div>
            <div className="flex justify-center py-[7px] w-[5%]">
                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase">Status</span>
            </div>
        </div>
    )
}