import axios from "axios";
import qs from 'qs';
import * as ENV from "../env";
import tokenService from '../services/token';

const axiosClientOtherURL = axios.create({
    baseURL: ENV.API_URL_OTHER,
    headers: {
        'content-type': 'multipart/form-data',
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
        }
    }
});

axiosClientOtherURL.interceptors.request.use(async (config) => {
    const token = tokenService.getToken();
    if (token)
        config.headers.Authorization = 'Bearer ' + token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClientOtherURL.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}, (error) => {
    if (error.response && error.response.data && error.response.data.error &&
        (error.response.data.session === false || error.response.data.session === "false")) {
        // alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
        window.location.href = "/";
    } else if (error?.response && error.response?.data && error.response.data.error && error.response.data.error.message) {
        return error?.response?.data
    } else if (error.response && error.response.status === 500) {
        return error?.response?.data;
    } else if (error?.response && error?.response?.status === 401) {
        return Promise.reject(error);
    } else if (error?.response && error?.response?.status === 403) {
        return Promise.reject(error)
    } else if (error?.response && error?.response?.status === 406) {
        return Promise.reject(error)
    }
    else {
        return error?.response?.data;
    }
    Promise.reject(error);
});

export default axiosClientOtherURL;