import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { bg_checked, checked } from '../../../../../components/imgExport';

function isInt(value: any) {
    return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
}

interface SoccerTopHDPFProps {
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    item: InfoGameDetailSoccer[]
    oddsOld?: Odd[]
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTopHDPH_V2: React.FC<SoccerTopHDPFProps> = ({
    homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip,
    updateOdds
}) => {
    const odd = useRef<any[]>([item])
    const oddOld = useRef<any[]>([oddsOld])
    const updateArrayOdds = async () => {
        item.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             // setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             // setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemState, setItemState] = useState<any[]>([item]);
    useEffect(() => {
        item.forEach(itemDetail => {
            itemDetail.prices.sort((a, b) => {
                if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                return 0; // Giữ nguyên thứ tự nếu option không phải "h"
            });
        });
        if (oddsOld) {
            oddsOld.forEach(itemDetail => {
                itemDetail.prices.sort((a, b) => {
                    if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                    if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                    return 0; // Giữ nguyên thứ tự nếu option không phải "h"
                });
            });
        }
        setItemState(item)
        odd.current = item
        oddOld.current = oddsOld ? oddsOld : item
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    // useEffect(() => {
    //     setTimeout(() => {
    //         oddOld.current = odd.current
    //     }, 3000);
    // }, [itemState])

    return (
        <>
            <div className='w-1/3 flex flex-col justify-start a1x2'>
                <div className='flex justify-between w-full h-[230px] items-center mt-4 border-dashed border-[#202531]'>
                    <div className='w-full flex flex-col hdp'>
                        <div className={`justify-center w-full flex items-center`}>
                            {/* // Fulltime HDP */}
                            {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[0]?.prices?.[0]?.price !== oddOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, (Number(itemState[0].point) !== 0 ? (-itemState[0].point) : "0") + " " + itemState[0].prices[0].price, itemState?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} 
                                ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "First Half HDP")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[0].id, homeTeam, (Number(itemState[0].point) !== 0 ? (-itemState[0].point) : "0") + " " + itemState[0].prices[0].price, itemState?.[0]?.prices?.[0]?.option
                                        // + (itemState[0].point >= 0 ? itemState[0].point : "")
                                    )}
                                >
                                    <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[0].point >= 0 ? (isInt((-Math.abs(itemState[0].point)).toFixed(2)) ? (-Math.abs(itemState[0].point)).toFixed(1) : (-Math.abs(itemState[0].point)).toFixed(2)) : ""}</p>
                                    <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm '>{Number(itemState[0].prices[0].price).toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "First Half HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                </div>}
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 1
                                ?
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price,
                                    () => (listChooseBet && odd.current?.[0]?.prices?.[1]?.price !== oddOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, (Number(itemState[0].point) !== 0 ? (itemState[0].point) : "0") + " " + itemState[0].prices[1].price, itemState?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} 
                                ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "First Half HDP")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[0].id, awayTeam, (Number(itemState[0].point) !== 0 ? (itemState[0].point) : "0") + " " + itemState[0].prices[1].price, itemState?.[0]?.prices?.[1]?.option
                                        // + (itemState[0].point < 0 ? itemState[0].point : "")
                                    )}
                                >
                                    <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[0].point < 0 ? (isInt((-Math.abs(itemState[0].point)).toFixed(2)) ? (-Math.abs(itemState[0].point)).toFixed(1) : (-Math.abs(itemState[0].point)).toFixed(2)) : ""}</p>
                                    <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm '>{Number(itemState[0].prices[1].price).toFixed(2)}</b></p>
                                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "First Half HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                </div>
                                :
                                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                                >
                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                </div>}
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative`}>
                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        </>
    );
};

export default SoccerTopHDPH_V2;