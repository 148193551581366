export const API_URL_365 = "https://api.kingbet365.com/";
// export const API_URL = "https://api2.kb365.bdata.cloud/";
export const API_URL = "https://api2.kingbet365.com/";
export const API_URL_SOCCER = "https://api1.kingbet365.com/";
// export const API_URL_BILL_ACCEPTOR = "http://api.kingbets360.com:5005/";
// export const API_URL = "http://api.kingbets360.bdata.link:5003/";
export const API_URL_OTHER = "http://124.158.13.78:8081/api/game/sbo/soccer";
// export const API_URL_OTHER = "http://124.158.13.78:8081/api/game/sbo/soccer/live-games-leagues";/live-games
export const API_URL_SBO = "http://sbo77.kingbets360.com:8081/";

export const ICON_COUNTRY = "https://cms.kingbet365.com/uploads/iconCountry/";
export const ICON_GAMME = "https://cms.kingbet365.com/uploads/iconGame/";
export const URL_BANNER = "https://cms.kingbet365.com/uploads/banner/";

export const ICON_TEAM = "https://media.itsfogo.com/media/upload/prod/participants/";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    process: {
        env: {
            API_URL_365,
            API_URL,
            ICON_COUNTRY,
            ICON_GAMME
        },
    },
};