
import { Modal } from "antd"
import { useSelector } from "react-redux"
import { selectorUser } from "../../store/slices/userSlice"
import { QuestionCircleOutlined } from "@ant-design/icons"

interface ModalBetsProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void
    handelConfirm: () => void
}
const DeleteAll = ({ showModal, setShowModal, handelConfirm }: ModalBetsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { userId, presentMoney } = useSelector(selectorUser);
    return (

        <Modal
            className='canPlayModal deleteAll flex justify-center'
            title={<QuestionCircleOutlined />}
            open={showModal}
            onOk={handelConfirm}
            onCancel={() => setShowModal(false)}
            okText="Delete all"
            cancelText="Cancel"
        >
            <p className="text-center !text-[18px] text-[#BB271A] !pb-1">Delete All</p>
        </Modal>
    )
}
export default DeleteAll