import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IModalLoginSlice {
    show: boolean,
    url: string
}

const initialModalLoginState: IModalLoginSlice = {
    show: false,
    url: "/tabs/homepage"
}

const modalLoginSlice = createSlice({
    name: 'modalLogin',
    initialState: initialModalLoginState,
    reducers: {
        setModalLogin: (state, action: PayloadAction<IModalLoginSlice>) => action.payload,
        setShowModalLogin: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload
        },
        setUrl: (state, action: PayloadAction<string>) => {
            state.url = action.payload
        },
        clearModalLogin: (state) => initialModalLoginState
    }
})

export const selectorModalLogin = (state: { modalLogin: IModalLoginSlice }) => state.modalLogin;
export const { setModalLogin, setShowModalLogin, setUrl, clearModalLogin } = modalLoginSlice.actions;
export default modalLoginSlice.reducer;