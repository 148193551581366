import { useDispatch, useSelector } from 'react-redux';
import { clearModalLogin, selectorModalLogin, setModalLogin, setShowModalLogin, setUrl } from '../store/slices/modalLoginSlice';
import useThrottle from './useThrottle';
// import { RootState } from 'đường dẫn đến file RootState'; // Thay đổi đường dẫn tới file RootState của bạn


// Tạo hook sử dụng useSelector và useDispatch
const useModalLogin = () => {
    const dispatch = useDispatch();
    const modalLogin = useSelector(selectorModalLogin); // Thay đổi tên reducer tương ứng

    const showModal = useThrottle((url: string) => {
        dispatch(setModalLogin({ show: true, url }));
    }, 1000);

    const hideModal = () => {
        dispatch(setShowModalLogin(false));
    };

    const setModalUrl = (url: string) => {
        dispatch(setUrl(url));
    };

    const clearLoginModal = () => {
        dispatch(clearModalLogin());
    };

    return {
        modalLogin,
        showModal,
        hideModal,
        setModalUrl,
        clearLoginModal,
    };
};

export default useModalLogin;
