/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-mixed-operators */
import { InfoOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from "react";
import { InfoBetTeaserTMP, InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from "../../../../api/betting/bettingApi";
import { formatPrice } from "../../../../until";
import useToast from "../../../../hook/useToast";
import useThrottle from "../../../../hook/useThrottle";
import { Close, warningBetslip } from '../../../../components/imgExport';
import { Drawer, Input } from "antd";
import CaculateComponent_V3 from './Caculate_V3';


interface TotalStakeProps {
    decimal?: string
    keyBetTabs: string,
    numberStake: number,

    textWarningBetParlay?: string
    isWarningBetParlay?: boolean
    isWarningBetTeaser?: boolean
    showTotalStake: boolean,
    showTotalStake_V2: boolean,
    showTotalBet: boolean,
    totalOddsParlay?: number
    totalAmountParlay?: number
    totalOddsTeaser?: number
    totalAmountTeaser?: number
    listAddStrightBetsSlip: InfoStrightBetsSlipTMP[]
    listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
    listTeaserBetsSlip?: InfoBetTeaserTMP[]
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleChangeInput?: (total: number) => void
    minInputStake?: () => void
    maxInputStake?: () => void
    showHideTotalStake: () => void,
    showHideTotalStake_V2: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBet: () => void
    handleCheckBetParlay?: () => void
    handleCheckBetTeaser?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    hiddenBetPlace?: boolean,
    minBet?: number,
    maxBet?: number,
}
const TotalStakeComponent = ({
    keyBetTabs, numberStake, textWarningBetParlay, isWarningBetParlay, isWarningBetTeaser,
    showTotalBet, showTotalStake,
    totalOddsParlay, totalAmountParlay, totalAmountTeaser, totalOddsTeaser,
    listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip, showTotalStake_V2,
    showHideTotalStake_V2, handleClick, showHideTotalStake, handleNumberClick, handleChangeInput, handleDeleteClick, handleCheckBet, handleCheckBetParlay, handleCheckBetTeaser, handleCheckQuantityMoneyStraight, minInputStake, maxInputStake,
    hiddenBetPlace,
    minBet, maxBet
}: TotalStakeProps) => {

    const pushToast = useToast();

    const [totalAmount, setTotalAmount] = useState(0)
    const [totalWin, setTotalWin] = useState(0)
    const [totalBet, setTotalBet] = useState(0)
    const [totalBetParlay, setTotalBetParlay] = useState(0)
    const [totalBetTeaser, setTotalBetTeaser] = useState(0)
    const [warningParlay, setWarningParlay] = useState<boolean>(false)

    const checkBetParlay = useThrottle(() => {
        if (isWarningBetParlay)
            pushToast(textWarningBetParlay ? textWarningBetParlay : "Parlay cannot be placed as some of your legs cannot be combined", "warn");
        // pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
        else {
            handleCheckBetParlay?.()
        }
    }, 1000)

    const checkBetTeaser = useThrottle(() => {
        if (isWarningBetTeaser)
            pushToast("Teaser cannot be placed as some of your legs cannot be combined", "warn");
        else {
            handleCheckBetTeaser?.()
        }
    }, 1000)

    const handleSuccess = () => {
        showHideTotalStake()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            case "3":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountTeaser))
                break;

            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }
    const handleSuccess_V2 = () => {
        showHideTotalStake_V2()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            case "3":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountTeaser))
                break;

            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }

    const changeInputStake = ((value: any) => {
        handleChangeInput && handleChangeInput(value)
    })

    useEffect(() => {
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? (accumulator + Number(currentItem.win)) : accumulator;
        }, 0);

        setTotalWin(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const totalAmountTmp = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmount(totalAmountTmp)
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? (accumulator + Number(currentItem.win)) : accumulator;
        }, 0);
        setTotalWin(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listAddStrightBetsSlip.filter((item) => item.isCheck);
        setTotalBet(filteredArray.length)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listParlayBetsSlip && listParlayBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetParlay(filteredArray.length)
    }, [listParlayBetsSlip])

    useMemo(() => {
        const filteredArray = listTeaserBetsSlip && listTeaserBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetTeaser(filteredArray.length)
    }, [listTeaserBetsSlip])

    useEffect(() => {
        setWarningParlay(false)
        if (totalBetParlay >= 2 && keyBetTabs === "2") {
            setTimeout(() => { setWarningParlay(isWarningBetParlay ?? false) }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWarningBetParlay, totalBetParlay])

    return (
        <>
            <div
                className={`totalStake bg-[#131620] !pt-1`}
                hidden={showTotalBet}
            >
                {/* (isWarningBetParlay && keyBetTabs === "2") && (totalBetParlay >= 2 && keyBetTabs === "2") */}
                <div
                    className={`border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] flex justify-start items-center ${totalBetParlay >= 2 && isWarningBetParlay && warningParlay && keyBetTabs === "2" ? "!flex" : "!hidden"} `}
                // hidden={(!isWarningBetParlay && keyBetTabs === "2") || keyBetTabs === "3" || keyBetTabs === "1" || (totalBetParlay < 2 && keyBetTabs === "2")}
                >
                    <img src={warningBetslip} alt="" />
                    <span className="pl-[6px] text-left text-[#D4B962]">{textWarningBetParlay ? textWarningBetParlay : "Parlay cannot be placed as some of your legs cannot be combined"}</span>
                </div>
                <div
                    className="border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] justify-start items-center"
                    hidden={(!isWarningBetTeaser && keyBetTabs === "3") || keyBetTabs === "1" || keyBetTabs === "2" || (totalBetTeaser < 2 && keyBetTabs === "3")}
                >
                    <img src={warningBetslip} alt="" />
                    <span className="pl-[6px] text-left text-[#D4B962]">Teaser cannot be placed as some of your selections cannot be combined</span>
                </div>
                <div
                    className="rounded-[6px] p-2 mt-0 text-left pl-3 border-[#D4B962] border-solid border-[1px] bg-transparent text-[#D4B962]"
                    hidden={(totalBetParlay > 1 && keyBetTabs === "2") || (totalBetTeaser > 1 && keyBetTabs === "3") || keyBetTabs === "1"}
                >
                    <InfoOutlined className="bg-[#D4B962] p-[1px] rounded-circle text-white" />
                    <span className="pl-[6px] text-left">Select at least 2 combinable picks</span>
                </div>
                <div className='flex flex-col gap-2 pt-2'>
                    <div className="flex  items-start justify-between gap-3">

                        <div className="w-full h-[33px] rounded-xl border border-[#E4E7F1] flex items-center justify-start px-3">
                            {
                                keyBetTabs === '1' && <Input disabled={window.outerWidth >= 1365 ? false : true} bordered={false} placeholder="Stake per bet" type="number" value={numberStake} onChange={(e) => changeInputStake(e.target.value)} className="!text-white placehoder-C1C9E1 input-stake" id="input-stake-mobile" />
                            }
                            {
                                keyBetTabs === '2' && <Input disabled={window.outerWidth >= 1365 ? false : true} bordered={false} placeholder="Stake per bet" type="number" value={totalAmountParlay} onChange={(e) => changeInputStake(e.target.value)} className="!text-white placehoder-C1C9E1 input-stake" id="input-stake-mobile" />
                            }
                            {
                                keyBetTabs === '3' && <Input disabled={window.outerWidth >= 1365 ? false : true} bordered={false} placeholder="Stake per bet" type="number" value={totalAmountTeaser} onChange={(e) => changeInputStake(e.target.value)} className="!text-white placehoder-C1C9E1 input-stake" id="input-stake-mobile" />
                            }
                            <span className="text-sm text-[#666E97]">$</span>
                        </div>

                    </div>
                    <CaculateComponent_V3
                        showTotalStake={false}
                        showTotalStake_V2={true}
                        showHideTotalStake={showHideTotalStake}
                        handleClick={handleClick}
                        handleNumberClick={handleNumberClick}
                        handleDeleteClick={handleDeleteClick}
                        handleSuccess={handleSuccess}
                        maxInputStake={maxInputStake}
                        keyBetTabs={keyBetTabs}
                        numberStake={numberStake}
                        totalAmountParlay={totalAmountParlay}
                        totalAmountTeaser={totalAmountTeaser ?? undefined}
                    />
                </div>

                <Drawer
                    placement="bottom"
                    closeIcon={false}
                    className="!bg-[#131620] p-none-drawer s:!block xl:!hidden"
                    maskClassName='xs:!block xl:!hidden'
                    title={<div className="flex justify-end" >
                        <img src={Close} alt="" onClick={() => showHideTotalStake_V2()} />
                    </div>}
                    open={showTotalStake_V2}
                    onClose={() => showHideTotalStake_V2()}

                >
                    <CaculateComponent_V3
                        showTotalStake={showTotalStake_V2}
                        showTotalStake_V2={showTotalStake_V2}
                        showHideTotalStake={showHideTotalStake_V2}
                        handleClick={handleClick}
                        handleNumberClick={handleNumberClick}
                        handleDeleteClick={handleDeleteClick}
                        handleSuccess={handleSuccess_V2}
                        keyBetTabs={keyBetTabs}
                        numberStake={numberStake}
                        totalAmountParlay={totalAmountParlay}
                        totalAmountTeaser={totalAmountTeaser ?? undefined}
                    />
                </Drawer>
                <div>
                    {
                        keyBetTabs === "1" &&
                        <>
                            {/* <span
                                className={`flex justify-start pb-2 text-white ${totalBet > 1 ? '' : 'hidden'}`}
                            >
                                {totalBet} Bets
                            </span> */}
                            <div className='flex flex-col gap-2 pt-2'>

                                <div className="flex flex-col gap-2">
                                    <div className='flex justify-between '>
                                        <span className="text-white text-base">Total stake</span>
                                        <span className='text-white font-bold-GT-America text-base'>${formatPrice(totalAmount || 0)}</span>
                                    </div>
                                    <div className='flex justify-between ' onClick={() => minInputStake && minInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer">Min</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(minBet || 0)}</span>
                                    </div>
                                    <div className='flex justify-between ' onClick={() => maxInputStake && maxInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer text-center ">Max</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(maxBet || 0)}</span>
                                    </div>
                                    <div className='flex  justify-between '>
                                        <span className="text-white text-base">Total win</span>
                                        <span className='text-[#33E49A] font-bold-GT-America text-base text-right'>{totalWin === 0 ? '$0' : `$${formatPrice(Math.round(totalWin) || 0)}`}</span>
                                    </div>
                                </div>
                                <div>
                                    <button className="font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={handleCheckBet}>Place bet</button>
                                </div>
                            </div>
                        </>

                    }
                    {
                        keyBetTabs === "2" &&
                        <>
                            {/* <span
                                className={`flex justify-start  text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span> */}
                            <div>
                                <div className="flex flex-col  gap-2">
                                    <div className='flex justify-between  text-white'>
                                        <span className="text-white text-base">Total odds</span>
                                        <span className='text-white font-bold-GT-America text-base'>{formatPrice(totalOddsParlay || 0)}</span>
                                    </div>
                                    <div className='flex justify-between ' onClick={() => minInputStake && minInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer ">Min</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(minBet || 0)}</span>
                                    </div>
                                    <div className='flex justify-between ' onClick={() => maxInputStake && maxInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer text-center ">Max</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(maxBet || 0)}</span>
                                    </div>
                                    <div className='flex justify-between  text-white'>
                                        <span className="text-white text-base">Total win</span>
                                        <span className='text-[#33E49A] font-bold-GT-America text-base text-right'>{totalAmountParlay === 0 ? '$0' : `$${formatPrice(Math.round(((totalAmountParlay || 0) * (totalOddsParlay || 0))) || 0)}`}</span>
                                    </div>
                                </div>

                                <div
                                >
                                    {!isWarningBetParlay && (
                                        <button className="font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={checkBetParlay}>Place bet</button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                    {
                        keyBetTabs === "3" &&
                        <>
                            {/* <span
                                className={`flex justify-start pb-5 text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span> */}
                            <div>
                                <div className='flex justify-between '>
                                    <span>Total odds</span>{ }
                                    <span className='font-bold-GT-America'>{formatPrice(Number(totalOddsTeaser) || 0)}</span>
                                </div>
                                <div className='flex justify-between ' onClick={() => minInputStake && minInputStake()}>
                                    <span className="text-[#D4B962] cursor-pointer ">Min</span>
                                    <span className="text-[#D4B962] cursor-pointer">${formatPrice(minBet || 0)}</span>
                                </div>
                                <div className='flex justify-between ' onClick={() => maxInputStake && maxInputStake()}>
                                    <span className="text-[#D4B962] cursor-pointer text-center ">Max</span>
                                    <span className="text-[#D4B962] cursor-pointer">${formatPrice(maxBet || 0)}</span>
                                </div>
                                <div className='flex justify-between '>
                                    <span>Total win</span>
                                    <span className='font-bold-GT-America text-[#33E49A]'>{totalAmountTeaser === 0 ? '$0' : `$${formatPrice(Math.round(((totalAmountTeaser || 0) * (totalOddsTeaser || 0))) || 0)}`}</span>
                                </div>
                                <div
                                >
                                    {!isWarningBetParlay && (
                                        <button className="font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] !bg-[#D4B962] !text-[#20273D]" onClick={checkBetTeaser}>Place bet</button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div >
            {/* <div className="w-[304px] toast-component-global">
                <ToastGlobal />
            </div> */}
        </>
    )
}
export default TotalStakeComponent