/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
// import { MenuItem } from "@mui/material";
import { Empty, type MenuProps, type TabsProps } from 'antd';
import { AllCountry, Australia, Baseball, Basketball, Bazil, Czech, Europe, FootballBlack, France, Germany, Greece, SoccerBlack, Star, USA } from '../components/imgExport';
import { WarningOutlined } from '@ant-design/icons';
import { InfoBreadcrumb } from "../page/homepage/components/Content/GameList/BreadCrumb";
import { LeftOutlined } from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];
export const dataMenu: MenuItem[] = [
    getItem('Top Events', 'mainMenu', <img src={Star} alt="Team 1" />, [
        getItem('Basketball', 'sub1', <img src={Basketball} alt="Basketball" />, [
            getItem(<div className='w-full flex justify-between countryNumber' >
                <span>USA</span>
                <span>22</span>
            </div>, '1', <img src={USA} alt="USA" />),
            getItem(
                <div className='w-full flex justify-between countryNumber'>
                    <span>Europe</span>
                    <span>22</span>
                </div>,
                '2',
                <img src={Europe} alt="Europe" />
            ),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Germany</span>
                <span>22</span>
            </div>, '3', <img src={Germany} alt="Germany" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Greece</span>
                <span>22</span>
            </div>, '4', <img src={Greece} alt="Greece" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Bazil</span>
                <span>22</span>
            </div>, '5', <img src={Bazil} alt="Bazil" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>France</span>
                <span>22</span>
            </div>, '6', <img src={France} alt="France" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Australia</span>
                <span>22</span>
            </div>, '7', <img src={Australia} alt="Australia" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Czech</span>
                <span>22</span>
            </div>, '8', <img src={Czech} alt="Czech" />),
        ]),
        getItem('Football', 'sub2', <img src={FootballBlack} alt="Football" />, [
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>USA</span>
                <span>22</span>
            </div>, '9', <img src={USA} alt="USA" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Europe</span>
                <span>22</span>
            </div>, '10', <img src={Europe} alt="Europe" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Germany</span>
                <span>22</span>
            </div>, '11', <img src={Germany} alt="Germany" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Greece</span>
                <span>22</span>
            </div>, '12', <img src={Greece} alt="Greece" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Bazil</span>
                <span>22</span>
            </div>, '13', <img src={Bazil} alt="Bazil" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>France</span>
                <span>22</span>
            </div>, '14', <img src={France} alt="France" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Australia</span>
                <span>22</span>
            </div>, '15', <img src={Australia} alt="Australia" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Czech</span>
                <span>22</span>
            </div>, '16', <img src={Czech} alt="Czech" />),
        ]),
        getItem('Baseball', 'sub3', <img src={Baseball} alt="Baseball" />, [
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>USA</span>
                <span>22</span>
            </div>, '17', <img src={USA} alt="USA" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Europe</span>
                <span>22</span>
            </div>, '18', <img src={Europe} alt="Europe" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Germany</span>
                <span>22</span>
            </div>, '19', <img src={Germany} alt="Germany" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Greece</span>
                <span>22</span>
            </div>, '20', <img src={Greece} alt="Greece" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Bazil</span>
                <span>22</span>
            </div>, '21', <img src={Bazil} alt="Bazil" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>France</span>
                <span>22</span>
            </div>, '22', <img src={France} alt="France" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Australia</span>
                <span>22</span>
            </div>, '23', <img src={Australia} alt="Australia" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Czech</span>
                <span>22</span>
            </div>, '24', <img src={Czech} alt="Czech" />),
        ]),
        getItem('Soccer', 'sub4', <img src={SoccerBlack} alt="Soccer" />, [
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>USA</span>
                <span>22</span>
            </div>, '25', <img src={USA} alt="USA" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Europe</span>
                <span>22</span>
            </div>, '26', <img src={Europe} alt="Europe" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Germany</span>
                <span>22</span>
            </div>, '27', <img src={Germany} alt="Germany" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Greece</span>
                <span>22</span>
            </div>, '28', <img src={Greece} alt="Greece" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Bazil</span>
                <span>22</span>
            </div>, '29', <img src={Bazil} alt="Bazil" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>France</span>
                <span>22</span>
            </div>, '30', <img src={France} alt="France" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Australia</span>
                <span>22</span>
            </div>, '31', <img src={Australia} alt="Australia" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Czech</span>
                <span>22</span>
            </div>, '32', <img src={Czech} alt="Czech" />),
        ]),
        getItem('All Countries', 'sub5', <img src={AllCountry} alt="All Countries" />, [
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>USA</span>
                <span>22</span>
            </div>, '33', <img src={USA} alt="USA" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Europe</span>
                <span>22</span>
            </div>, '34', <img src={Europe} alt="Europe" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Germany</span>
                <span>22</span>
            </div>, '35', <img src={Germany} alt="Germany" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Greece</span>
                <span>22</span>
            </div>, '36', <img src={Greece} alt="Greece" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Bazil</span>
                <span>22</span>
            </div>, '37', <img src={Bazil} alt="Bazil" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>France</span>
                <span>22</span>
            </div>, '38', <img src={France} alt="France" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Australia</span>
                <span>22</span>
            </div>, '39', <img src={Australia} alt="Australia" />),
            getItem(<div className='w-full flex justify-between countryNumber'>
                <span>Czech</span>
                <span>22</span>
            </div>, '40', <img src={Czech} alt="Czech" />),
        ]),
    ]),
];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}


export const dataMenuV2 = [
    {
        key: 'mainMenu',
        icon: <img src={Star} alt="Team 1" />,
        label: 'Top Events',
        type: 'group',
        children: [
            {
                key: 'sub1',
                icon: <img src={Basketball} alt="Basketball" />,
                label: 'Basketball',
                children: [
                    {
                        key: '1',
                        icon: <img src={USA} alt="USA" />,
                        label: (
                            <div className='w-full flex justify-between countryNumber'>
                                <span>USA</span>
                                <span>22</span>
                            </div>
                        ),
                    },
                    // Add other basketball items as needed
                ],
            },
            {
                key: 'sub2',
                icon: <img src={FootballBlack} alt="Football" />,
                label: 'Football',
                children: [
                    {
                        key: '9',
                        icon: <img src={USA} alt="USA" />,
                        label: (
                            <div className='w-full flex justify-between countryNumber'>
                                <span>USA</span>
                                <span>22</span>
                            </div>
                        ),
                    },
                    // Add other football items as needed
                ],
            },
            // Add other top-level menu items as needed
        ],
    },
];

type MenuItemGroupType<ItemType extends MenuItemType> = {
    key: React.Key;
    label?: React.ReactNode;
    type?: 'group';
    children?: React.ReactElement<ItemType>[];
};

type MenuItemType = {
    key: React.Key;
    label?: React.ReactNode;
    icon?: React.ReactNode;
};

type MenuItemV2 = MenuItemType & {
    children?: React.ReactElement<MenuItemType>[];
};


export const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Straight',
        children:
            <>
                <Empty className="pl-11 pr-11 text-center" description='Add selections to your Betslip to get started' />
            </>,
    },
    {
        key: '2',
        label: 'Parlay',
        children:
            <>
                <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
                    <WarningOutlined />
                    <span className="pl-2">2+ selections needed for a Parlay.</span>
                </div>
                <Empty className="pl-2 pr-2 text-center" description='A Parlay is a bet on two or more teams or selections. You can combine different sports,points, spreads and money lines. To win the parlay all selections mustwin.' />
            </>,
    },
    {
        key: '3',
        label: 'Teaser',
        children:
            <>
                <div className="flex items-start rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
                    <WarningOutlined className="pt-1" />
                    <span className="pl-2 text-left">2+ Football or Basketball selections needed.
                        for a Teaser bet.
                    </span>
                </div>
                <Empty className="pl-2 pr-2" description='Teasers allow you to combine 2+ Football or Basketball selections from the Spread and Total Match Point bet types and allow you to alter the spread, to increase the probability of winning.' />
            </>,
    },
    {
        key: '4',
        label: 'If Bet',
        children:
            <>
                <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
                    <WarningOutlined />
                    <span className="pl-2">2+ Bets are needed to create an IF Bet.</span>
                </div>
                <Empty className="pl-2 pr-2 text-center" description='Wager on your chosen 1st selection, if that selecton wins, then an amount up to the original “ to win " amount will be wagered on your next selection. If your 1st selection losses. There is no action afterwards, your bet is a loser.' />
            </>,
    },
    {
        key: '5',
        label: 'Reverse',
        children:
            <>
                <div className="flex items-center rounded-md pr-3 pl-3 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
                    <WarningOutlined />
                    <span className="pl-1">2+ Bets are needed to create an Reverse.</span>
                </div>
                <Empty className="pl-2 pr-2 text-center" description='An" Action Reverse " bet links multiple bets: the next bet is activated regardless of the previous bets outcome, as long as the event occurs.' />
            </>,
    },
];


export const WinningMargin = [
    {
        id: '1',
        name: 'Timberwolves by 1-5',
        total: '5.00'
    },
    {
        id: '2',
        name: 'Magic by 1-5',
        total: '5.50'
    },
    {
        id: '3',
        name: 'Timberwolves by 6-10',
        total: '5.25'
    },
    {
        id: '4',
        name: 'Magic by 6-10',
        total: '8.00'
    },
    {
        id: '5',
        name: 'Timberwolves by 11-15',
        total: '6.00'
    },
    {
        id: '6',
        name: 'Magic by 11-15',
        total: '12.50'
    }
]
export const TotalPoints = [
    {
        id: '1',
        name: '150 or less',
        total: '151.00'
    },
    {
        id: '2',
        name: '151 to 160',
        total: '151.00'
    },
    {
        id: '3',
        name: '161 to 170',
        total: '151.00'
    },
    {
        id: '4',
        name: '171 to 180',
        total: '51.00'
    },
    {
        id: '5',
        name: '181 to 190',
        total: '16.50'
    },
    {
        id: '6',
        name: '191 to 200',
        total: '7.50'
    },
    {
        id: '7',
        name: '201 to 210',
        total: '4.40'
    },
    {
        id: '8',
        name: '211 to 220',
        total: '3.50'
    },
    {
        id: '9',
        name: '221 to 230',
        total: '4.00'
    },
    {
        id: '10',
        name: '231 to 240',
        total: '6.25'
    },
    {
        id: '11',
        name: '241 or more',
        total: '9.00'
    },
]

export const itemTabs: TabsProps['items'] = [
    {
        key: '1',
        label: <div className='flex'><span className='text-[#898989] font-normal'>Events</span><span className='text-[#898989] bg-[#F0F0F0] rounded-circle ml-[10px] flex items-center justify-center h-[20px] w-[20px]'>9</span></div>,
    },
    {
        key: '2',
        label: <div className='flex'><span className='text-[#898989] font-normal'>Futures</span><span className='text-[#898989] bg-[#F0F0F0] rounded-circle ml-[10px] flex items-center justify-center h-[20px] w-[20px]'>0</span></div>,
    },
];

export const itemsDF: InfoBreadcrumb[] = [
    {
        href: '',
        title: <LeftOutlined />,
    },
    {
        type: 'separator',
        separator: '|',
    },
    // {
    //     href: '',
    //     title: (

    //         <span className='flex'>
    //             <img
    //                 className='pr-1 opacity-50 w-5 h-5'
    //                 src={ICON_GAMME + infoChooseGame?.icon}
    //                 alt="Basketball"
    //                 onError={(e) => {
    //                     e.currentTarget.src = image404;
    //                 }}
    //             />
    //             {infoChooseGame?.lable || ""}
    //         </span>

    //     ),
    // },
    // {
    //     type: 'separator',
    //     separator: '/',
    // },
    // {
    //     title: (
    //         <span>
    //             <img
    //                 className=" w-[40px] h-5"
    //                 src={iconNFL}
    //                 alt="NBAColor"
    //             />
    //         </span>
    //     ),
    // }
]

export const dataLve = [
    {
        "id": "16582-27968-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Northern Arizona",
        "away_team": "Northern Colorado",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "16582-27968-2024-03-04:betmgm:total_points:over_152_5",
                "sports_book_name": "BetMGM",
                "name": "Over 152.5",
                "price": 1.91,
                "timestamp": 1709603983.3788176,
                "bet_points": 152.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074070279--1189553775&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 152.5
            },
            {
                "id": "16582-27968-2024-03-04:betmgm:total_points:under_152_5",
                "sports_book_name": "BetMGM",
                "name": "Under 152.5",
                "price": 1.83,
                "timestamp": 1709603983.3788176,
                "bet_points": 152.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074070279--1189553774&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 152.5
            },
            {
                "id": "16582-27968-2024-03-04:betmgm:moneyline:northern_arizona",
                "sports_book_name": "BetMGM",
                "name": "Northern Arizona",
                "price": 6.25,
                "timestamp": 1709603978.19685,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074070270--1189553792&type=Single",
                "player_id": null,
                "selection": "Northern Arizona",
                "normalized_selection": "northern_arizona",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "16582-27968-2024-03-04:betmgm:moneyline:northern_colorado",
                "sports_book_name": "BetMGM",
                "name": "Northern Colorado",
                "price": 1.12,
                "timestamp": 1709603978.19685,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074070270--1189553793&type=Single",
                "player_id": null,
                "selection": "Northern Colorado",
                "normalized_selection": "northern_colorado",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "16582-27968-2024-03-04:betmgm:point_spread:northern_arizona_+9_5",
                "sports_book_name": "BetMGM",
                "name": "Northern Arizona +9.5",
                "price": 1.8,
                "timestamp": 1709603978.19685,
                "bet_points": 9.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074464645--1188517441&type=Single",
                "player_id": null,
                "selection": "Northern Arizona",
                "normalized_selection": "northern_arizona",
                "selection_line": null,
                "selection_points": 9.5
            },
            {
                "id": "16582-27968-2024-03-04:betmgm:point_spread:northern_colorado_-9_5",
                "sports_book_name": "BetMGM",
                "name": "Northern Colorado -9.5",
                "price": 1.95,
                "timestamp": 1709603978.19685,
                "bet_points": -9.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502171-1074464645--1188517442&type=Single",
                "player_id": null,
                "selection": "Northern Colorado",
                "normalized_selection": "northern_colorado",
                "selection_line": null,
                "selection_points": -9.5
            }
        ]
    },
    {
        "id": "20123-73872-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "McNeese",
        "away_team": "Houston Christian",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": []
    },
    {
        "id": "21669-18274-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Northern Colorado",
        "away_team": "Northern Arizona",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAW",
        "odds": []
    },
    {
        "id": "27329-17543-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Texas A&M-Corpus Christi",
        "away_team": "New Orleans",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "27329-17543-2024-03-04:betmgm:total_points:over_142_5",
                "sports_book_name": "BetMGM",
                "name": "Over 142.5",
                "price": 1.83,
                "timestamp": 1709603673.7196305,
                "bet_points": 142.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074459281--1188531704&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 142.5
            },
            {
                "id": "27329-17543-2024-03-04:betmgm:total_points:under_142_5",
                "sports_book_name": "BetMGM",
                "name": "Under 142.5",
                "price": 1.91,
                "timestamp": 1709603673.7196305,
                "bet_points": 142.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074459281--1188531703&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 142.5
            },
            {
                "id": "27329-17543-2024-03-04:betmgm:moneyline:new_orleans",
                "sports_book_name": "BetMGM",
                "name": "New Orleans",
                "price": 6.25,
                "timestamp": 1709603629.138244,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074070235--1189553920&type=Single",
                "player_id": null,
                "selection": "New Orleans",
                "normalized_selection": "new_orleans",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "27329-17543-2024-03-04:betmgm:moneyline:texas_a_m-corpus_christi",
                "sports_book_name": "BetMGM",
                "name": "Texas A&M-Corpus Christi",
                "price": 1.12,
                "timestamp": 1709603629.138244,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074070235--1189553919&type=Single",
                "player_id": null,
                "selection": "Texas A&M-Corpus Christi",
                "normalized_selection": "texas_a_m-corpus_christi",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "27329-17543-2024-03-04:betmgm:point_spread:new_orleans_+9_5",
                "sports_book_name": "BetMGM",
                "name": "New Orleans +9.5",
                "price": 1.83,
                "timestamp": 1709603629.138244,
                "bet_points": 9.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074448252--1188558967&type=Single",
                "player_id": null,
                "selection": "New Orleans",
                "normalized_selection": "new_orleans",
                "selection_line": null,
                "selection_points": 9.5
            },
            {
                "id": "27329-17543-2024-03-04:betmgm:point_spread:texas_a_m-corpus_christi_-9_5",
                "sports_book_name": "BetMGM",
                "name": "Texas A&M-Corpus Christi -9.5",
                "price": 1.91,
                "timestamp": 1709603629.138244,
                "bet_points": -9.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502168-1074448252--1188558966&type=Single",
                "player_id": null,
                "selection": "Texas A&M-Corpus Christi",
                "normalized_selection": "texas_a_m-corpus_christi",
                "selection_line": null,
                "selection_points": -9.5
            }
        ]
    },
    {
        "id": "27843-12967-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Milwaukee Bucks",
        "away_team": "Los Angeles Clippers",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NBA",
        "odds": [
            {
                "id": "27843-12967-2024-03-04:betmgm:total_points:over_218_5",
                "sports_book_name": "BetMGM",
                "name": "Over 218.5",
                "price": 1.91,
                "timestamp": 1709604070.2427993,
                "bet_points": 218.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1074445349--1188566076&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 218.5
            },
            {
                "id": "27843-12967-2024-03-04:betmgm:total_points:under_218_5",
                "sports_book_name": "BetMGM",
                "name": "Under 218.5",
                "price": 1.83,
                "timestamp": 1709604070.2427993,
                "bet_points": 218.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1074445349--1188566075&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 218.5
            },
            {
                "id": "27843-12967-2024-03-04:betmgm:moneyline:los_angeles_clippers",
                "sports_book_name": "BetMGM",
                "name": "Los Angeles Clippers",
                "price": 1.36,
                "timestamp": 1709604055.8958678,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1066852013--1208370562&type=Single",
                "player_id": null,
                "selection": "Los Angeles Clippers",
                "normalized_selection": "los_angeles_clippers",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "27843-12967-2024-03-04:betmgm:moneyline:milwaukee_bucks",
                "sports_book_name": "BetMGM",
                "name": "Milwaukee Bucks",
                "price": 3.1,
                "timestamp": 1709604055.8958678,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1066852013--1208370561&type=Single",
                "player_id": null,
                "selection": "Milwaukee Bucks",
                "normalized_selection": "milwaukee_bucks",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "27843-12967-2024-03-04:betmgm:point_spread:los_angeles_clippers_-5_5",
                "sports_book_name": "BetMGM",
                "name": "Los Angeles Clippers -5.5",
                "price": 1.83,
                "timestamp": 1709604055.8958678,
                "bet_points": -5.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1074451011--1188552385&type=Single",
                "player_id": null,
                "selection": "Los Angeles Clippers",
                "normalized_selection": "los_angeles_clippers",
                "selection_line": null,
                "selection_points": -5.5
            },
            {
                "id": "27843-12967-2024-03-04:betmgm:point_spread:milwaukee_bucks_+5_5",
                "sports_book_name": "BetMGM",
                "name": "Milwaukee Bucks +5.5",
                "price": 1.91,
                "timestamp": 1709604055.8958678,
                "bet_points": 5.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432708-1074451011--1188552384&type=Single",
                "player_id": null,
                "selection": "Milwaukee Bucks",
                "normalized_selection": "milwaukee_bucks",
                "selection_line": null,
                "selection_points": 5.5
            }
        ]
    },
    {
        "id": "41811-38874-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Minnesota Timberwolves",
        "away_team": "Portland Trail Blazers",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NBA",
        "odds": [
            {
                "id": "41811-38874-2024-03-04:betmgm:point_spread:minnesota_timberwolves_-13_5",
                "sports_book_name": "BetMGM",
                "name": "Minnesota Timberwolves -13.5",
                "price": 1.91,
                "timestamp": 1709603926.4853477,
                "bet_points": -13.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1074436549--1188586952&type=Single",
                "player_id": null,
                "selection": "Minnesota Timberwolves",
                "normalized_selection": "minnesota_timberwolves",
                "selection_line": null,
                "selection_points": -13.5
            },
            {
                "id": "41811-38874-2024-03-04:betmgm:point_spread:portland_trail_blazers_+13_5",
                "sports_book_name": "BetMGM",
                "name": "Portland Trail Blazers +13.5",
                "price": 1.83,
                "timestamp": 1709603926.4853477,
                "bet_points": 13.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1074436549--1188586953&type=Single",
                "player_id": null,
                "selection": "Portland Trail Blazers",
                "normalized_selection": "portland_trail_blazers",
                "selection_line": null,
                "selection_points": 13.5
            },
            {
                "id": "41811-38874-2024-03-04:betmgm:total_points:over_212_5",
                "sports_book_name": "BetMGM",
                "name": "Over 212.5",
                "price": 1.87,
                "timestamp": 1709603947.904652,
                "bet_points": 212.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1074436784--1188586466&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 212.5
            },
            {
                "id": "41811-38874-2024-03-04:betmgm:total_points:under_212_5",
                "sports_book_name": "BetMGM",
                "name": "Under 212.5",
                "price": 1.87,
                "timestamp": 1709603947.904652,
                "bet_points": 212.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1074436784--1188586465&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 212.5
            },
            {
                "id": "41811-38874-2024-03-04:betmgm:moneyline:minnesota_timberwolves",
                "sports_book_name": "BetMGM",
                "name": "Minnesota Timberwolves",
                "price": 1.07,
                "timestamp": 1709603926.4853477,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1066852112--1208370272&type=Single",
                "player_id": null,
                "selection": "Minnesota Timberwolves",
                "normalized_selection": "minnesota_timberwolves",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "41811-38874-2024-03-04:betmgm:moneyline:portland_trail_blazers",
                "sports_book_name": "BetMGM",
                "name": "Portland Trail Blazers",
                "price": 8.5,
                "timestamp": 1709603926.4853477,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432709-1066852112--1208370273&type=Single",
                "player_id": null,
                "selection": "Portland Trail Blazers",
                "normalized_selection": "portland_trail_blazers",
                "selection_line": null,
                "selection_points": null
            }
        ]
    },
    {
        "id": "91110-33681-2024-03-04",
        "start_date": "2024-03-04T20:00:00-05:00",
        "home_team": "Texas A&M Commerce",
        "away_team": "Lamar",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "91110-33681-2024-03-04:betmgm:total_points:over_147_5",
                "sports_book_name": "BetMGM",
                "name": "Over 147.5",
                "price": 1.87,
                "timestamp": 1709604053.9358797,
                "bet_points": 147.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074440788--1188576620&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 147.5
            },
            {
                "id": "91110-33681-2024-03-04:betmgm:total_points:under_147_5",
                "sports_book_name": "BetMGM",
                "name": "Under 147.5",
                "price": 1.87,
                "timestamp": 1709604053.9358797,
                "bet_points": 147.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074440788--1188576619&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 147.5
            },
            {
                "id": "91110-33681-2024-03-04:betmgm:moneyline:lamar",
                "sports_book_name": "BetMGM",
                "name": "Lamar",
                "price": 1.06,
                "timestamp": 1709604063.5404856,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074070247--1189553877&type=Single",
                "player_id": null,
                "selection": "Lamar",
                "normalized_selection": "lamar",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "91110-33681-2024-03-04:betmgm:moneyline:texas_a_m_commerce",
                "sports_book_name": "BetMGM",
                "name": "Texas A&M Commerce",
                "price": 8.75,
                "timestamp": 1709604063.5404856,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074070247--1189553876&type=Single",
                "player_id": null,
                "selection": "Texas A&M Commerce",
                "normalized_selection": "texas_a_m_commerce",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "91110-33681-2024-03-04:betmgm:point_spread:lamar_-11_5",
                "sports_book_name": "BetMGM",
                "name": "Lamar -11.5",
                "price": 1.95,
                "timestamp": 1709604063.5404856,
                "bet_points": -11.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074449496--1188556063&type=Single",
                "player_id": null,
                "selection": "Lamar",
                "normalized_selection": "lamar",
                "selection_line": null,
                "selection_points": -11.5
            },
            {
                "id": "91110-33681-2024-03-04:betmgm:point_spread:texas_a_m_commerce_+11_5",
                "sports_book_name": "BetMGM",
                "name": "Texas A&M Commerce +11.5",
                "price": 1.8,
                "timestamp": 1709604063.5404856,
                "bet_points": 11.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502169-1074449496--1188556062&type=Single",
                "player_id": null,
                "selection": "Texas A&M Commerce",
                "normalized_selection": "texas_a_m_commerce",
                "selection_line": null,
                "selection_points": 11.5
            }
        ]
    },
    {
        "id": "94187-26224-2024-03-04",
        "start_date": "2024-03-04T19:50:00-05:00",
        "home_team": "Norfolk State",
        "away_team": "Maryland-Eastern Shore",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "94187-26224-2024-03-04:betmgm:point_spread:maryland-eastern_shore_+7_5",
                "sports_book_name": "BetMGM",
                "name": "Maryland-Eastern Shore +7.5",
                "price": 1.87,
                "timestamp": 1709604049.1557684,
                "bet_points": 7.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074445782--1188565083&type=Single",
                "player_id": null,
                "selection": "Maryland-Eastern Shore",
                "normalized_selection": "maryland-eastern_shore",
                "selection_line": null,
                "selection_points": 7.5
            },
            {
                "id": "94187-26224-2024-03-04:betmgm:point_spread:norfolk_state_-7_5",
                "sports_book_name": "BetMGM",
                "name": "Norfolk State -7.5",
                "price": 1.87,
                "timestamp": 1709604049.1557684,
                "bet_points": -7.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074445782--1188565082&type=Single",
                "player_id": null,
                "selection": "Norfolk State",
                "normalized_selection": "norfolk_state",
                "selection_line": null,
                "selection_points": -7.5
            },
            {
                "id": "94187-26224-2024-03-04:betmgm:moneyline:maryland-eastern_shore",
                "sports_book_name": "BetMGM",
                "name": "Maryland-Eastern Shore",
                "price": 4.75,
                "timestamp": 1709604049.1557684,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074070177--1189554114&type=Single",
                "player_id": null,
                "selection": "Maryland-Eastern Shore",
                "normalized_selection": "maryland-eastern_shore",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "94187-26224-2024-03-04:betmgm:moneyline:norfolk_state",
                "sports_book_name": "BetMGM",
                "name": "Norfolk State",
                "price": 1.18,
                "timestamp": 1709604049.1557684,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074070177--1189554113&type=Single",
                "player_id": null,
                "selection": "Norfolk State",
                "normalized_selection": "norfolk_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "94187-26224-2024-03-04:betmgm:total_points:over_134_5",
                "sports_book_name": "BetMGM",
                "name": "Over 134.5",
                "price": 1.91,
                "timestamp": 1709604049.1557684,
                "bet_points": 134.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074425330--1188613604&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 134.5
            },
            {
                "id": "94187-26224-2024-03-04:betmgm:total_points:under_134_5",
                "sports_book_name": "BetMGM",
                "name": "Under 134.5",
                "price": 1.83,
                "timestamp": 1709604049.1557684,
                "bet_points": 134.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502164-1074425330--1188613603&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 134.5
            }
        ]
    },
    {
        "id": "26412-33911-2024-03-04",
        "start_date": "2024-03-04T19:48:00-05:00",
        "home_team": "Coppin State",
        "away_team": "South Carolina State",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "26412-33911-2024-03-04:betmgm:point_spread:coppin_state_+6_5",
                "sports_book_name": "BetMGM",
                "name": "Coppin State +6.5",
                "price": 1.8,
                "timestamp": 1709603563.5416076,
                "bet_points": 6.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074442695--1188572009&type=Single",
                "player_id": null,
                "selection": "Coppin State",
                "normalized_selection": "coppin_state",
                "selection_line": null,
                "selection_points": 6.5
            },
            {
                "id": "26412-33911-2024-03-04:betmgm:point_spread:south_carolina_state_-6_5",
                "sports_book_name": "BetMGM",
                "name": "South Carolina State -6.5",
                "price": 1.95,
                "timestamp": 1709603563.5416076,
                "bet_points": -6.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074442695--1188572010&type=Single",
                "player_id": null,
                "selection": "South Carolina State",
                "normalized_selection": "south_carolina_state",
                "selection_line": null,
                "selection_points": -6.5
            },
            {
                "id": "26412-33911-2024-03-04:betmgm:moneyline:coppin_state",
                "sports_book_name": "BetMGM",
                "name": "Coppin State",
                "price": 3.7,
                "timestamp": 1709603563.5416076,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074070207--1189554015&type=Single",
                "player_id": null,
                "selection": "Coppin State",
                "normalized_selection": "coppin_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "26412-33911-2024-03-04:betmgm:moneyline:south_carolina_state",
                "sports_book_name": "BetMGM",
                "name": "South Carolina State",
                "price": 1.29,
                "timestamp": 1709603563.5416076,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074070207--1189554016&type=Single",
                "player_id": null,
                "selection": "South Carolina State",
                "normalized_selection": "south_carolina_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "26412-33911-2024-03-04:betmgm:total_points:over_130_5",
                "sports_book_name": "BetMGM",
                "name": "Over 130.5",
                "price": 1.87,
                "timestamp": 1709603563.5416076,
                "bet_points": 130.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074450227--1188554185&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 130.5
            },
            {
                "id": "26412-33911-2024-03-04:betmgm:total_points:under_130_5",
                "sports_book_name": "BetMGM",
                "name": "Under 130.5",
                "price": 1.87,
                "timestamp": 1709603563.5416076,
                "bet_points": 130.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502166-1074450227--1188554184&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 130.5
            }
        ]
    },
    {
        "id": "18107-15665-2024-03-04",
        "start_date": "2024-03-04T19:46:00-05:00",
        "home_team": "Morgan State",
        "away_team": "North Carolina Central",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "18107-15665-2024-03-04:betmgm:point_spread:morgan_state_-1_5",
                "sports_book_name": "BetMGM",
                "name": "Morgan State -1.5",
                "price": 1.87,
                "timestamp": 1709604073.7866416,
                "bet_points": -1.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502163-1074070163--1189554161&type=Single",
                "player_id": null,
                "selection": "Morgan State",
                "normalized_selection": "morgan_state",
                "selection_line": null,
                "selection_points": -1.5
            },
            {
                "id": "18107-15665-2024-03-04:betmgm:point_spread:north_carolina_central_+1_5",
                "sports_book_name": "BetMGM",
                "name": "North Carolina Central +1.5",
                "price": 1.87,
                "timestamp": 1709604073.7866416,
                "bet_points": 1.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502163-1074070163--1189554162&type=Single",
                "player_id": null,
                "selection": "North Carolina Central",
                "normalized_selection": "north_carolina_central",
                "selection_line": null,
                "selection_points": 1.5
            },
            {
                "id": "18107-15665-2024-03-04:betmgm:moneyline:morgan_state",
                "sports_book_name": "BetMGM",
                "name": "Morgan State",
                "price": 1.74,
                "timestamp": 1709604073.7866416,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502163-1074070162--1189554163&type=Single",
                "player_id": null,
                "selection": "Morgan State",
                "normalized_selection": "morgan_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "18107-15665-2024-03-04:betmgm:moneyline:north_carolina_central",
                "sports_book_name": "BetMGM",
                "name": "North Carolina Central",
                "price": 2.05,
                "timestamp": 1709604073.7866416,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502163-1074070162--1189554164&type=Single",
                "player_id": null,
                "selection": "North Carolina Central",
                "normalized_selection": "north_carolina_central",
                "selection_line": null,
                "selection_points": null
            }
        ]
    },
    {
        "id": "33152-22796-2024-03-04",
        "start_date": "2024-03-04T19:30:00-05:00",
        "home_team": "Brooklyn Nets",
        "away_team": "Memphis Grizzlies",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NBA",
        "odds": [
            {
                "id": "33152-22796-2024-03-04:betmgm:total_points:over_211_5",
                "sports_book_name": "BetMGM",
                "name": "Over 211.5",
                "price": 1.91,
                "timestamp": 1709604070.2427993,
                "bet_points": 211.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1074434464--1188592282&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 211.5
            },
            {
                "id": "33152-22796-2024-03-04:betmgm:total_points:under_211_5",
                "sports_book_name": "BetMGM",
                "name": "Under 211.5",
                "price": 1.87,
                "timestamp": 1709604070.2427993,
                "bet_points": 211.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1074434464--1188592281&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 211.5
            },
            {
                "id": "33152-22796-2024-03-04:betmgm:point_spread:brooklyn_nets_-4_5",
                "sports_book_name": "BetMGM",
                "name": "Brooklyn Nets -4.5",
                "price": 1.91,
                "timestamp": 1709604070.2427993,
                "bet_points": -4.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1074435827--1188588898&type=Single",
                "player_id": null,
                "selection": "Brooklyn Nets",
                "normalized_selection": "brooklyn_nets",
                "selection_line": null,
                "selection_points": -4.5
            },
            {
                "id": "33152-22796-2024-03-04:betmgm:point_spread:memphis_grizzlies_+4_5",
                "sports_book_name": "BetMGM",
                "name": "Memphis Grizzlies +4.5",
                "price": 1.83,
                "timestamp": 1709604070.2427993,
                "bet_points": 4.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1074435827--1188588899&type=Single",
                "player_id": null,
                "selection": "Memphis Grizzlies",
                "normalized_selection": "memphis_grizzlies",
                "selection_line": null,
                "selection_points": 4.5
            },
            {
                "id": "33152-22796-2024-03-04:betmgm:moneyline:brooklyn_nets",
                "sports_book_name": "BetMGM",
                "name": "Brooklyn Nets",
                "price": 1.4,
                "timestamp": 1709604070.2427993,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1066844729--1208390776&type=Single",
                "player_id": null,
                "selection": "Brooklyn Nets",
                "normalized_selection": "brooklyn_nets",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "33152-22796-2024-03-04:betmgm:moneyline:memphis_grizzlies",
                "sports_book_name": "BetMGM",
                "name": "Memphis Grizzlies",
                "price": 2.9,
                "timestamp": 1709604070.2427993,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15432589-1066844729--1208390777&type=Single",
                "player_id": null,
                "selection": "Memphis Grizzlies",
                "normalized_selection": "memphis_grizzlies",
                "selection_line": null,
                "selection_points": null
            }
        ]
    },
    {
        "id": "37406-67283-2024-03-04",
        "start_date": "2024-03-04T19:30:00-05:00",
        "home_team": "Incarnate Word",
        "away_team": "Southeastern Louisiana",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "37406-67283-2024-03-04:betmgm:point_spread:incarnate_word_+13_5",
                "sports_book_name": "BetMGM",
                "name": "Incarnate Word +13.5",
                "price": 1.87,
                "timestamp": 1709604049.1557684,
                "bet_points": 13.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502162-1074465544--1188514860&type=Single",
                "player_id": null,
                "selection": "Incarnate Word",
                "normalized_selection": "incarnate_word",
                "selection_line": null,
                "selection_points": 13.5
            },
            {
                "id": "37406-67283-2024-03-04:betmgm:point_spread:southeastern_louisiana_-13_5",
                "sports_book_name": "BetMGM",
                "name": "Southeastern Louisiana -13.5",
                "price": 1.87,
                "timestamp": 1709604049.1557684,
                "bet_points": -13.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502162-1074465544--1188514861&type=Single",
                "player_id": null,
                "selection": "Southeastern Louisiana",
                "normalized_selection": "southeastern_louisiana",
                "selection_line": null,
                "selection_points": -13.5
            },
            {
                "id": "37406-67283-2024-03-04:betmgm:total_points:over_131_5",
                "sports_book_name": "BetMGM",
                "name": "Over 131.5",
                "price": 1.87,
                "timestamp": 1709604049.155782,
                "bet_points": 131.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502162-1074464534--1188517697&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 131.5
            },
            {
                "id": "37406-67283-2024-03-04:betmgm:total_points:under_131_5",
                "sports_book_name": "BetMGM",
                "name": "Under 131.5",
                "price": 1.87,
                "timestamp": 1709604049.155782,
                "bet_points": 131.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502162-1074464534--1188517696&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 131.5
            }
        ]
    },
    {
        "id": "40105-38843-2024-03-04",
        "start_date": "2024-03-04T19:30:00-05:00",
        "home_team": "Howard",
        "away_team": "Delaware State",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "half",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "40105-38843-2024-03-04:betmgm:total_points:over_151_5",
                "sports_book_name": "BetMGM",
                "name": "Over 151.5",
                "price": 1.91,
                "timestamp": 1709603233.8045273,
                "bet_points": 151.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074440753--1188576690&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 151.5
            },
            {
                "id": "40105-38843-2024-03-04:betmgm:total_points:under_151_5",
                "sports_book_name": "BetMGM",
                "name": "Under 151.5",
                "price": 1.83,
                "timestamp": 1709603233.8045273,
                "bet_points": 151.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074440753--1188576689&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 151.5
            },
            {
                "id": "40105-38843-2024-03-04:betmgm:point_spread:delaware_state_+10_5",
                "sports_book_name": "BetMGM",
                "name": "Delaware State +10.5",
                "price": 1.87,
                "timestamp": 1709603208.834846,
                "bet_points": 10.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074456411--1188538388&type=Single",
                "player_id": null,
                "selection": "Delaware State",
                "normalized_selection": "delaware_state",
                "selection_line": null,
                "selection_points": 10.5
            },
            {
                "id": "40105-38843-2024-03-04:betmgm:point_spread:howard_-10_5",
                "sports_book_name": "BetMGM",
                "name": "Howard -10.5",
                "price": 1.87,
                "timestamp": 1709603208.834846,
                "bet_points": -10.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074456411--1188538387&type=Single",
                "player_id": null,
                "selection": "Howard",
                "normalized_selection": "howard",
                "selection_line": null,
                "selection_points": -10.5
            },
            {
                "id": "40105-38843-2024-03-04:betmgm:moneyline:delaware_state",
                "sports_book_name": "BetMGM",
                "name": "Delaware State",
                "price": 7.5,
                "timestamp": 1709603208.834846,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074072681--1189547646&type=Single",
                "player_id": null,
                "selection": "Delaware State",
                "normalized_selection": "delaware_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "40105-38843-2024-03-04:betmgm:moneyline:howard",
                "sports_book_name": "BetMGM",
                "name": "Howard",
                "price": 1.08,
                "timestamp": 1709603204.07367,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502219-1074072681--1189547645&type=Single",
                "player_id": null,
                "selection": "Howard",
                "normalized_selection": "howard",
                "selection_line": null,
                "selection_points": null
            }
        ]
    },
    {
        "id": "75511-35658-2024-03-04",
        "start_date": "2024-03-04T19:30:00-05:00",
        "home_team": "Northwestern State",
        "away_team": "Nicholls",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": [
            {
                "id": "75511-35658-2024-03-04:betmgm:point_spread:nicholls_-6_5",
                "sports_book_name": "BetMGM",
                "name": "Nicholls -6.5",
                "price": 1.87,
                "timestamp": 1709604045.4892461,
                "bet_points": -6.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074432734--1188596198&type=Single",
                "player_id": null,
                "selection": "Nicholls",
                "normalized_selection": "nicholls",
                "selection_line": null,
                "selection_points": -6.5
            },
            {
                "id": "75511-35658-2024-03-04:betmgm:point_spread:northwestern_state_+6_5",
                "sports_book_name": "BetMGM",
                "name": "Northwestern State +6.5",
                "price": 1.87,
                "timestamp": 1709604045.4892461,
                "bet_points": 6.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Point Spread",
                "market": "point_spread",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074432734--1188596197&type=Single",
                "player_id": null,
                "selection": "Northwestern State",
                "normalized_selection": "northwestern_state",
                "selection_line": null,
                "selection_points": 6.5
            },
            {
                "id": "75511-35658-2024-03-04:betmgm:moneyline:nicholls",
                "sports_book_name": "BetMGM",
                "name": "Nicholls",
                "price": 1.14,
                "timestamp": 1709604045.4892461,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074070192--1189554065&type=Single",
                "player_id": null,
                "selection": "Nicholls",
                "normalized_selection": "nicholls",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "75511-35658-2024-03-04:betmgm:moneyline:northwestern_state",
                "sports_book_name": "BetMGM",
                "name": "Northwestern State",
                "price": 5.75,
                "timestamp": 1709604045.4892461,
                "bet_points": null,
                "is_main": true,
                "is_live": true,
                "market_name": "Moneyline",
                "market": "moneyline",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074070192--1189554064&type=Single",
                "player_id": null,
                "selection": "Northwestern State",
                "normalized_selection": "northwestern_state",
                "selection_line": null,
                "selection_points": null
            },
            {
                "id": "75511-35658-2024-03-04:betmgm:total_points:over_137_5",
                "sports_book_name": "BetMGM",
                "name": "Over 137.5",
                "price": 1.95,
                "timestamp": 1709604049.155782,
                "bet_points": 137.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074432697--1188596276&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "over",
                "selection_points": 137.5
            },
            {
                "id": "75511-35658-2024-03-04:betmgm:total_points:under_137_5",
                "sports_book_name": "BetMGM",
                "name": "Under 137.5",
                "price": 1.8,
                "timestamp": 1709604049.155782,
                "bet_points": 137.5,
                "is_main": true,
                "is_live": true,
                "market_name": "Total Points",
                "market": "total_points",
                "home_rotation_number": null,
                "away_rotation_number": null,
                "deep_link_url": "https://sports.<STATE>.betmgm.com/en/sports?options=15502165-1074432697--1188596275&type=Single",
                "player_id": null,
                "selection": "",
                "normalized_selection": "",
                "selection_line": "under",
                "selection_points": 137.5
            }
        ]
    },
    {
        "id": "34537-21072-2024-03-04",
        "start_date": "2024-03-04T19:00:00-05:00",
        "home_team": "North Carolina State",
        "away_team": "Duke",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": []
    },
    {
        "id": "35526-10632-2024-03-04",
        "start_date": "2024-03-04T19:00:00-05:00",
        "home_team": "Florida Gulf Coast",
        "away_team": "Queens University",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": []
    },
    {
        "id": "78349-16988-2024-03-04",
        "start_date": "2024-03-04T19:00:00-05:00",
        "home_team": "Kennesaw State",
        "away_team": "Jacksonville",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "basketball",
        "league": "NCAAB",
        "odds": []
    },
    {
        "id": "40673-54558-2024-03-04-15",
        "start_date": "2024-03-04T18:40:00-05:00",
        "home_team": "Miami Marlins",
        "away_team": "New York Yankees",
        "is_live": true,
        "is_popular": false,
        "tournament": null,
        "status": "live",
        "sport": "baseball",
        "league": "MLB",
        "odds": []
    }
]