/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
// import { Checkbox } from 'antd';
// import { DeleteOutlined } from '@ant-design/icons';
import BetSlipTab from '../../BetSlipTab/BetSlipTab';
import { InfoAddStrightSoccerTmp, InfoBetTeaserTMP, InfoParlaySoccerTmp } from '../../../../../api/betting/bettingApi';
// import ItemStraight from './Component/ItemStraight';
// import ItemParlay from './Component/ItemParlay';
// import ItemTeaser from './Component/ItemTeaser';
// import IconSlider from './Component/Teaser';
import ItemStraightSoccer from './Component/ItemStraightSoccer';
import ItemParlaySoccer from './Component/ItemParlaySoccer';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import './styles.css'

interface BetTabsSoccerProps {
    menu?: any
    keyGameTab?: "gameOnline" | "gameOffline"
    keyBetTabs: string
    totalStake: number
    pointTeaser?: number
    listAddStrightBetsSlip?: InfoAddStrightSoccerTmp[]
    listParlayBetsSlip?: InfoParlaySoccerTmp[]
    listTeaserBetsSlip?: InfoBetTeaserTMP[]
    listBetStrightSoccerWaiting?: bettingApi.InfoBetStrightSoccer[]
    setKeyBetTabs: (value: string) => void
    rStrightBetsSlipByIndex: (value: number) => void
    clearAll: () => void;
    setListaddStrightBetsSlip: (value: InfoAddStrightSoccerTmp[]) => void
    setListParlayBetsSlip?: (value: InfoParlaySoccerTmp[]) => void
    setListTeaserBetsSlip?: (value: InfoBetTeaserTMP[]) => void
    setTotalStake: (index: number) => void
    setPointTeaser?: (value: number) => void
    chooseElement: string
}

const BetTabsSoccer: React.FC<BetTabsSoccerProps> = ({
    menu, keyGameTab, keyBetTabs,
    listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip, listBetStrightSoccerWaiting,

    setKeyBetTabs, rStrightBetsSlipByIndex, clearAll, setListaddStrightBetsSlip, setListParlayBetsSlip, setListTeaserBetsSlip, setTotalStake, setPointTeaser,

    chooseElement,


}) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [settingClicked, setSettingClicked] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showTabs, setShowTabs] = useState(false);
    const [isAllCheckedStright, setIsAllCheckedStright] = useState(false);
    const [isAllCheckedParlay, setIsAllCheckedParlay] = useState(false);
    const settingbet = menu?.map((item: any) => item.key)



    const handleCheckAll = (value: boolean) => {
        if (listAddStrightBetsSlip?.length) {
            const updatedArray = listAddStrightBetsSlip.map(item => ({
                ...item,
                isCheck: value
            }));
            setListaddStrightBetsSlip(updatedArray)
        }
    }
    const handleCheckAllParlay = (value: boolean) => {
        if (listParlayBetsSlip?.length) {
            const updatedArray = listParlayBetsSlip.map(item => ({
                ...item,
                isCheck: value
            }));
            setListParlayBetsSlip?.(updatedArray)
        }
    }

    useMemo(() => {
        if (listAddStrightBetsSlip?.length) {
            const allTrue: boolean = listAddStrightBetsSlip.every(item => item.isCheck);
            setIsAllCheckedStright(allTrue)
        }
    }, [listAddStrightBetsSlip])

    useEffect(() => {
        if (listParlayBetsSlip?.length) {
            const allTrue: boolean = listParlayBetsSlip.every(item => item.isCheck);
            setIsAllCheckedParlay(allTrue)
        }

    }, [listParlayBetsSlip])

    const items: TabsProps['items'] = keyGameTab === "gameOffline" ? ([
        {
            key: '1',
            label: settingbet?.includes('1') ? 'Straight' : '',
            children:
                <div className={`betTabs flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    {
                        listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                            <ItemStraightSoccer
                                key={i}
                                index={i}
                                item={item}
                                listAddStrightBetsSlip={listAddStrightBetsSlip}
                                setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                setTotalStake={setTotalStake}
                            />
                        ))
                    }

                    {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
                        <Checkbox
                            checked={isAllCheckedStright}
                            onChange={(e) => handleCheckAll(e.target.checked)}
                        ></Checkbox>
                        <div className='flex flex-row-reverse'>
                            <Button
                                className='!flex items-center pl-2 pr-1'
                                type="text"
                                onClick={clearAll}
                            >
                                <DeleteOutlined
                                    className='text-[#000000]'
                                />
                                <span className='text-black font-[500]'
                                >Clear All
                                </span>
                            </Button>
                        </div>
                    </div> */}
                    <BetSlipTab showTabs={showTabs} />
                </div>,
        },
        {
            key: '2',
            label: settingbet?.includes('2') ? 'Parlay' : '',
            children: <div className={`betTabs pl-2 flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                {
                    listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
                        <ItemParlaySoccer
                            key={i}
                            index={i}
                            item={item}
                            listParlayBetsSlip={listParlayBetsSlip}
                            rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                            setListParlayBetsSlip={setListParlayBetsSlip}
                        // setTotalStake={setTotalStake}
                        />
                    ))}
                <BetSlipTab showTabs={showTabs} />
            </div>,
        },
    ]) : ([
        {
            key: '1',
            label: settingbet?.includes('1') ? 'Straight' : '',
            children:
                <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    {
                        listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                            <ItemStraightSoccer
                                key={i}
                                index={i}
                                item={item}
                                listAddStrightBetsSlip={listAddStrightBetsSlip}
                                setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                setTotalStake={setTotalStake}
                            />
                        ))
                    }
                    <BetSlipTab showTabs={showTabs} />
                </div>,
        }])

    return (
        <div>
            <Tabs items={items} activeKey={keyBetTabs} onChange={(e) => {
                setKeyBetTabs(e.toString());
            }} className='tab-bet-home tab-bet-soccer' />
        </div>
    );
};

export default BetTabsSoccer;