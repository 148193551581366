import { Checkbox, Modal } from "antd"
import * as homePage_V2Api from '../../api/homePage/homePage_V2Api';
import './styles.css'
import { leaf_1, leaf_2 } from "../imgExport";
import { CloseCircleOutlined } from "@ant-design/icons"
interface ModalProp {
    dataTournament_2: homePage_V2Api.InfoTournamentSoccer_V2[]
    showModalTourName: boolean
    listChooseTournament?: number[]
    chooseTournament?: (tournament: string, key: number) => void
    closeModal: (value: boolean) => void
    clearTournament?: () => void
}


export const ModalTourName: React.FC<ModalProp> = ({
    showModalTourName, dataTournament_2, listChooseTournament,
    chooseTournament,
    closeModal,
    clearTournament
}) => {

    return (
        <Modal
            centered
            open={showModalTourName}
            onCancel={() => closeModal(false)}
            closable={false}
            footer={null}
            className="custome-modal_V2"
        >
            <div className="flex flex-col gap-4 ">
                <div className="flex justify-between items-center bg-gradient-A98F34 w-full px-5">
                    <div className="w-[150px] flex justify-start"><span className='text-sm uppercase font-medium p-1 rounded bg-[#DBBF64] text-[#131620] cursor-pointer' onClick={(e) => clearTournament && clearTournament()}>clear all  </span></div>
                    <div className=' py-2 flex justify-center items-center gap-2  '>
                        <img src={leaf_1} alt="" />
                        <span className='text-lg uppercase  fw-[400] text-[#E4E7F1] '>tournament EVENT</span>
                        <img src={leaf_2} alt="" />
                    </div>
                    <div className="w-[150px] flex justify-end">
                        <CloseCircleOutlined
                            className="text-red-600 cursor-pointer text-[24px]"
                            onClick={() => closeModal(false)}
                        />
                    </div>

                </div>

                <div className="flex flex-col justify-start gap-2 px-6">

                    <div className="grid grid-cols-3 gap-2 h-[430px] overflow-y-scroll pt-2">
                        {dataTournament_2.map((item, index) => (
                            <div
                                key={index}
                                className={`flex items-start gap-2 pb-2 !uppercase hover:text-[#D4B962] ${listChooseTournament && listChooseTournament.includes(item.key) ? '!text-[#D4B962]' : 'text-white'}`}
                            >
                                <Checkbox
                                    checked={listChooseTournament && listChooseTournament.includes(item.key)}
                                    onClick={e => chooseTournament && chooseTournament(item.tournament, item.key)}
                                    // onChange={e => onChange(item.tournament, e.target.checked)}
                                    className="!text-white custom_checkbox"
                                />
                                {item.tournament}
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    )
}