/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectorUser, setUserMoney } from '../../../../../store/slices/userSlice';
import { formatPrice } from '../../../../../until';
import LoadingModal from '../../../../../components/Modal/LoadingModal';
import WithDraw from '../../../../../components/Modal/WithDrawModal';
import { getPresentMoney, getRefundMoney } from '../../../../../api/billAcceptor/billAcceptorApi';
import useToast from '../../../../../hook/useToast';
import ModalWarning from '../../../../../components/Modal/ModalWarning';
// import io from 'socket.io-client';
import ModalBillRefund from '../../../../../components/Modal/ModalBillRefund';
// import { API_URL_BILL_ACCEPTOR } from '../../../../../env';
import useLoading from '../../../../../hook/useLoading';
import { TransactionWhite } from '../../../../../components/imgExport';
import useThrottle from '../../../../../hook/useThrottle';

const HeadRight: React.FC = () => {
  // const navigator = useNavigate()
  // const { resetLoginOut } = useResetData()
  const { userId, presentMoney, authCode } = useSelector(selectorUser);
  const pushToast = useToast();
  const dispatch = useDispatch();
  const pushLoading = useLoading();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [socket, setSocket] = useState<any>(null);

  const [money, setMoney] = useState("")
  const [billCode, setBillCode] = useState("")
  const [showLoading, setShowLoading] = useState(false)
  const [showWithDraw, setWithDraw] = useState(false)
  const [showModalWarning, setShowModalWarning] = useState(false)
  const [showModalBill, setShowModalBill] = useState(false)
  const [time, setTime] = useState(0)
  // useEffect(() => {
  //   const socketInstance = io(API_URL_BILL_ACCEPTOR);
  //   setSocket(socketInstance);

  //   socketInstance.on('connect', () => {
  //     console.log('Connected to server get bill acceptor');
  //   });
  //   // socketInstance.emit("get-bill-acceptor", Number(authCode));
  //   socketInstance.on('bill-acceptor', async (data: any) => {
  //     if (data.authcode === authCode) {
  //       pushLoading(true)
  //       dispatch(setUserMoney(data.current_money))
  //       pushToast("You have successfully deposited money", "success")
  //       pushLoading(false)
  //     }
  //     // dispatch(setUserMoney(data))
  //   });
  //   // socketInstance.on('bill-acceptor', async (data) => {
  //   //   dispatch(setUserMoney(data))
  //   // });
  //   return () => {
  //     if (socketInstance) {
  //       socketInstance.disconnect();
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  const getCurrentMoney = useThrottle(async () => {
    pushLoading(true);
    var timeTotal = 10
    const interval = setInterval(() => {
      if (timeTotal > 0) {
        timeTotal -= 1
        setTime(timeTotal)
      } else {
        clearInterval(interval)
      }
    }, 1000);
    const ketQua = await getPresentMoney();
    if (ketQua?.success) {
      dispatch(setUserMoney(ketQua.data.currentMoney))
      setMoney(ketQua.data.currentMoney)
    }
    setTimeout(() => {
      pushLoading(false);
    }, 1000)
  }, 10000)

  const handelConfirm = async () => {
    setWithDraw(false)
    setShowLoading(true);
    if (Number(presentMoney) > 0) {
      const ketQua = await getRefundMoney(userId, presentMoney);
      if (ketQua?.success) {
        // logOut()
        await setBillCode(ketQua.data.billCode)
        await setMoney(presentMoney)
        await (setShowModalBill(true))
        handleResetMoney()
      } else {
        if (ketQua.type === "nem") {
          dispatch(setUserMoney(ketQua.data.presentMoney))
          setShowModalWarning(true)
        }
        pushToast(ketQua?.message, "warn");
      }
    }
    setShowLoading(false);
  }

  const handleResetMoney = () => {
    dispatch(setUserMoney("0"))
  };

  const handleCloseModalBill = () => {
    setShowModalBill(false)
    // dispatch(setUserMoney("0"))
  };

  // const logOut = () => {
  //   resetLoginOut()
  //   navigator("/otp-confirm")
  // }

  useEffect(() => {
    getCurrentMoney()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex gap="small" wrap="wrap" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

      <LoadingModal
        showModal={showLoading}
        setShowModal={setShowLoading}
      />

      <WithDraw
        showModal={showWithDraw}
        setShowModal={setWithDraw}
        handelConfirm={handelConfirm}
      />

      <ModalWarning
        showModal={showModalWarning}
        setShowModal={setShowModalWarning}
      />

      <ModalBillRefund
        billCode={billCode}
        showModalBill={showModalBill}
        totalAmount={+money}
        setShowModalBill={handleCloseModalBill}
      />
      <Button
        type="primary"
        style={{
          paddingLeft: '6px',
          paddingRight: '6px',
          color: '#D4B962',
          height: '38px',
          background: 'transparent',
          // border: '1px solid rgba(255, 206, 112, 0.4), 1px solid rgba(188, 102, 55, 0.4), 1px solid rgba(0, 0, 0, 0.4)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'unset'
        }}
      // onClick={() => setWithDraw(true)}
      >
        ${formatPrice(presentMoney || 0)}
      </Button>
      <div className='pl-1 cursor-pointer h-10 text-white flex items-center gap-2' onClick={() => getCurrentMoney()}>
        {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />}
        <span className='lg:text-xs  xl:text-xs xl2:text-sm xl3:text-sm fw-[400] uppercase text-white h-fit'>Reload</span>
      </div>
    </Flex>
  );
}

export default HeadRight;

