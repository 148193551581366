/* eslint-disable no-unreachable */
import { Link } from "react-router-dom";
import { KB_best_sport_img, contact, logoKB_2 } from "../../components/imgExport";
import './style.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Checkbox, Form, Input } from "antd";
import { useState } from "react";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import ToastGlobal from "../../components/ToastGlobal";
import * as LoginApi from '../../api/login/loginApi';
import tokenService from '../../services/token';

const Forgot: React.FC = () => {
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();

    const [forGot, setForGot] = useState(1)
    const onFinish = async (values: any) => {
        if (forGot === 1) {
            // quên mật khẩu
            pushLoading(true)
            if (values.username.length < 6) {
                dispatch(clearAlert())
                pushToast("UserName length must be at least 6 characters long", "warn");
                pushLoading(false)
                return
            }
            const ketQua = await LoginApi.forgotPasswordWithUsername(values.username)
            if (ketQua?.success) {
                tokenService.setCodeConfirm(ketQua?.data?.code)
                tokenService.setEmailComfirm(ketQua?.data?.email)
                window.location.href = "/forgotbyemail"
            } else {
                dispatch(clearAlert())
                pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
                pushLoading(false)
                return
            }
            // 
            pushLoading(false)
        } else {
            // quên tài khoản
            pushLoading(true)
            if (values.username.length < 6) {
                dispatch(clearAlert())
                pushToast("Email is not in correct format", "warn");
                pushLoading(false)
                return
            }
            const ketQua = await LoginApi.forgotUsername(values.username)
            if (ketQua?.success) {
                dispatch(clearAlert())
                pushToast("The system has sent a username to your email. Please get the username to log in.", "success", 5000);
                pushLoading(false)
                setTimeout(() => {
                    window.location.href = "/login"
                }, 5000)
                return
            } else {
                dispatch(clearAlert())
                pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
                pushLoading(false)
                return
            }
            // 
            pushLoading(false)
        }
    };

    return (
        <div className='h-[100vh] !w-full flex  !justify-center !items-center bg-login-new'>
            <div className='flex flex-col xs:w-[343px] xs:gap-3 sm:w-[343px] sm:gap-3 md:w-[694px] md:gap-4 lg:w-[694px] lg:gap-4 items-center rounded-[24px] border border-[#454449] bg-[#14171F]'>
                <div className='flex flex-col items-center xs:gap-3 sm:gap-3 md:gap-6 lg:gap-6 '>
                    <div className="xs:pt-[24px] sm:pt-[24px] md:pt-[44px] lg:pt-[44px]">
                        <img src={logoKB_2} alt="" className="xs:w-[60px] xs:h-[60px] sm:w-[60px] sm:h-[60px] md:w-[120px] md:h-[120px] lg:w-[120px] lg:h-[120px]" />
                    </div>
                    <img src={KB_best_sport_img} alt="" />
                    <span className="xs:text-xs sm:text-xs md:text-lg lg:text-lg text-[#E4E7F1] uppercase">wha’t your problem?</span>
                </div>
                <ToastGlobal />
                <div className="flex items-center gap-3">
                    <div className="xs:pl-3 xs:py-2 sm:pl-3 sm:py-2 md:px-4 md:py-3 lg:px-4 lg:py-3 flex items-center border border-[#4B5563] rounded-lg  bg-[#374151]">
                        <Checkbox
                            className={`pt-checkbox ${forGot === 1 && 'custom-checkbox'}`}
                            checked={forGot === 1}
                            onChange={() => setForGot(1)}>
                            <span className="xs:font-normal sm:font-normal md:font-bold-GT-America lg:font-bold-GT-America xs:text-xs sm:text-xs md:text-sm lg:text-sm uppercase text-[#D1D5DB] mt-1 whitespace-nowrap">
                                Forgot Password
                            </span>
                        </Checkbox>
                    </div>
                    <div className="xs:pl-3 xs:py-2 sm:pl-3 sm:py-2 md:px-4 md:py-3 lg:px-4 lg:py-3 flex items-center border border-[#4B5563] rounded-lg  bg-[#374151]">
                        <Checkbox
                            className={`pt-checkbox ${forGot === 2 && 'custom-checkbox'}`}
                            checked={forGot === 2}
                            onChange={() => setForGot(2)}>
                            <span className="xs:font-normal sm:font-normal md:font-bold-GT-America lg:font-bold-GT-America xs:text-xs sm:text-xs md:text-sm lg:text-sm uppercase text-[#D1D5DB] mt-1 whitespace-nowrap">
                                Forgot Username
                            </span>
                        </Checkbox>
                    </div>
                </div>
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    className="xs:w-[279px] sm:w-[279x] md:w-[356px] lg:w-[356px]"
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                        className="custom-input rounded-lg border-4B5563"
                    >
                        <Input
                            prefix={<AccountCircleIcon className="text-[#C1C9E1] xs:!text-xs sm:!text-xs md:!text-2xl lg:!text-2xl" />}
                            placeholder={forGot === 1 ? 'Your username' : 'Your register phone number or email'}
                            size="large"
                            className="!text-[#C1C9E1] placehoder-C1C9E1"
                        />
                    </Form.Item>
                    <div className="flex flex-col gap-6 items-center w-full xs:pb-0 sm:pb-0 md:pb-6 lg:pb-6">
                        <button className="xs:text-xs  sm:text-sm md:text-sm lg:text-sm bg-[#E1C467] text-[#131620]  rounded-lg xs:py-3 sm:py-[14px] md:py-[14px] lg:py-[14px] w-full font-bold-GT-America uppercase"
                            type="submit"
                        >

                            {forGot === 1 ? 'Yes, reset password' : 'next'}
                        </button>
                    </div>
                </Form>
                <div className="flex gap-[14px] xs:pb-6 sm:pb-6 md:pb-[59px] lg:pb-[59px]">
                    <img src={contact} alt="" />
                    <div className="flex flex-col">
                        <span className="uppercase text-sm text-[#FFFFFF] fw-[500]">Need support?</span>
                        <Link to={'/'} className="text-[12px] text-[#D4B962] uppercase">contact us</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}




export default Forgot;