/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import HeadTabs from './HeadTabs';
import { baketball, baketballActive, baseball, baseballActive, logout, NCAAF, rugby, rugbyActive, soccer, soccerActice } from '../../../../../components/imgExport';
import * as homePageApi from '../../../../../api/homePage/homePageApi'
import * as loginApi from '../../../../../api/login/loginApi';
import { InfoParlayBetsSlipTMP, InfoStrightBetsSlipTMP } from '../../../../../api/betting/bettingApi';
import tokenService from '../../../../../services/token';
export interface itemMenu {
  id: number;
  name: string;
  icon: string;
  iconActive?: string;
  href: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const itemMenu: itemMenu[] = [
  {
    id: 1,
    name: 'Basketball',
    href: '/NBA',
    icon: baketball,
    iconActive: baketball,
  },
  {
    id: 2,
    name: 'Football',
    href: '/NFL',
    icon: rugby,
    iconActive: rugby,
  },
  {
    id: 6,
    name: 'Baseball',
    href: '/MLB',
    icon: baseball,
    iconActive: baseball,
  },
  {
    id: 9,
    name: 'Soccer',
    href: '/Soccer',
    icon: soccer,
    iconActive: soccer,
  },

]
interface Props {
  handleOpenDrawerBestSlip?: (value: boolean) => void
  openDrawer?: boolean
  closeDrawer?: (value: boolean) => void
  keyBetTabs?: string
  listAddStrightBetsSlip?: InfoStrightBetsSlipTMP[]
  listParlayBetsSlip?: InfoParlayBetsSlipTMP[]
  tournaments: itemMenu
  setTournaments: (value: itemMenu) => void
}
const HeadNavi: React.FC<Props> = ({
  listAddStrightBetsSlip, keyBetTabs, openDrawer, tournaments, listParlayBetsSlip,
  closeDrawer, handleOpenDrawerBestSlip, setTournaments
}) => {
  const [menu, setMenu] = useState<itemMenu[]>([])
  const [OTPCode, setOTPCode] = useState<string>("")
  const checkSelect = (value: string) => {
    return value === tournaments.href ? "!text-[#D4B962] " : "text-[#C1C9E1]"
  }

  const getAuthcodeByToken = async () => {
    const ketqua = await loginApi.getAuthcodeByToken()
    // if (ketqua?.authCode) {
    //   setOTPCode(ketqua.authCode)
    // }
  }

  // viewSettings
  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    setMenu([{
      id: 0,
      name: 'Soccer',
      href: '/Soccer_V2',
      icon: soccer,
      iconActive: soccerActice,
    }])
    if (ketqua?.success) {
      if (ketqua.data.navigation.NBA === 1) {
        setMenu((data: any) => [...data, {
          id: 1,
          name: 'Basketball',
          href: '/NBA',
          icon: baketball,
          iconActive: baketballActive,
        }])
      }
      if (ketqua.data.navigation.NFL === 1) {
        setMenu((data: any) => [...data, {
          id: 2,
          name: 'Football',
          href: '/NFL',
          icon: rugby,
          iconActive: rugbyActive,
        }])
      }
      if (ketqua.data.navigation.MLB === 1) {
        setMenu((data: any) => [...data, {
          id: 6,
          name: 'Baseball',
          href: '/MLB',
          icon: baseball,
          iconActive: baseballActive,
        }])
      }
      if (ketqua.data.navigation.NCAAF === 1) {
        setMenu((data: any) => [...data, {
          id: 3,
          name: 'NCAAF',
          href: '/NCAAF',
          icon: NCAAF,
          iconActive: NCAAF,
        }])
      }
      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 9,
      //     name: 'Soccer',
      //     href: '/Soccer',
      //     icon: Soccer
      //   }])
      // }
      // if (ketqua.data.navigation.NCAAF === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 3,
      //     name: 'NCAAF',
      //     href: '/NCAAF',
      //     icon: NCAAF
      //   }])
      // }


      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 10,
      //     name: 'Soccer',
      //     href: '/Soccer_V2',
      //     icon: Soccer
      //   }])
      // }

    }
  }

  useEffect(() => {
    viewSettings()
    getAuthcodeByToken()
  }, [])
  return (
    <div className="flex xl:flex-col xs:flex-row justify-between xs:items-center fixed top-0 left-0 z-50  bg-[#131620]  xs:h-[76px] xs:w-full xl:h-[100vh] xl:w-[110px] xs:border-b xs:border-b-[#202531] xl:border-0">
      <div className="xs:w-full xl:w-auto">
        <HeadTabs
          keyBetTabs={keyBetTabs ?? ''}
          listAddStrightBetsSlip={listAddStrightBetsSlip}
          listParlayBetsSlip={listParlayBetsSlip}
          openDrawer={openDrawer}
          menu={menu}
          tournaments={tournaments}
          setTournaments={setTournaments}
          handleOpenDrawerBestSlip={handleOpenDrawerBestSlip ? handleOpenDrawerBestSlip : undefined}
          closeDrawer={() => closeDrawer}
        />
        <div className='xs:justify-normal xl:justify-between xl:flex xl:items-center xl:flex-col xl:w-[110px] xl:bg-none xl:top-[45px] xs:fixed xs:flex xs:flex-row xs:w-full xs:bottom-0 xs:bg-[#131620] xs:border-t xs:border-t-[#202531] xl:border-t-0  xs:border-r-0 xl:border-r xl:border-r-[#202531]'>
          <div className='w-full xs:flex xl:block'>
            {menu.map((items) => (
              <div key={items.id} className={` py-6 border-b border-b-[#202531] w-full cursor-pointer xs:text-sm lg:text-xs  xl2:text-base uppercase  fw-[500]   text-[#C1C9E1] hover:text-[#D4B962] div-hover-sidebar   ${checkSelect(items.name)}`}>
                <div
                  className=' flex justify-start items-center py-[17px] xs:px-5 xs:pl-0'
                  onClick={() => setTournaments(items)}
                >
                  <div className='flex items-center gap-2 xs:text-sm lg:text-xs xl:text-xs xl2:text-sm uppercase fw-[500] pl-2'>
                    {items.href === tournaments.href ?
                      <img src={items.iconActive} alt="" />
                      :
                      <>
                        <img src={items.iconActive} alt="" className='hidden img-hover w-5 h-5' />
                        <img src={items.icon} alt="" className='block img-hover-hidden w-5 h-5' />
                      </>

                    }
                    <span className={`xs:text-sm lg:text-xs xl:text-xs xl2:text-sm uppercase fw-[500] ${checkSelect(items.href)}`}>{items.name}</span>
                  </div>
                </div>
              </div>

            ))}
          </div>

          <button className='xs:hidden xl:flex border-t border-t-[#202531] h-[56px]  justify-center items-center gap-3 w-full '>
            <img src={logout} alt="" />
            <span className='text-sm font-medium text-[#C1C9E1]'
              onClick={() => {
                tokenService.removeToken()
                window.location.href = "/login";
              }}
            >
              LOGOUT
            </span>
          </button>
        </div>
      </div>


      <div className='text-[#222222] pr-5'>
        {OTPCode}
      </div>
    </div>
  )

}
export default HeadNavi;